import React, { useState, useEffect, useRef } from "react";
import Lottie from "lottie-react";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Select,
  message,
  Tooltip,
  Row,
  Col,
  Image,
  Card,
  Typography,
  Tag,
  InputNumber,
  Popconfirm,
  Space,
} from "antd";
import {
  PlusOutlined,
  SearchOutlined,
  LoadingOutlined,
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {
  ReadRecord,
  CreateRecord,
  UpdateRecord,
} from "../../Service/API/ApiService";
import QrScanner from "../../Assests/QRscanner.png";
import QRLoading from "../../Assests/qr-code.json";
import QRLoading2 from "../../Assests/QrScannerLoading.gif";
import { BsQrCodeScan } from "react-icons/bs";
import useScanDetection from "use-scan-detection";
import { type } from "@testing-library/user-event/dist/type";
import formatDate from "../../Components/formatDate";

const Store = () => {
  const harnetRoleAccess = JSON.parse(localStorage.getItem("harnetRoleAccess")) || [];
  const userRoleHarnet = localStorage.getItem("userRoleHarnet") || [];
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [filteredInfo, setFilteredInfo] = useState({});
  const [searchedColumn, setSearchedColumn] = useState("");
  const [storeData, setStoreData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [mode, setMode] = useState("view"); // 'view' or 'edit'
  const [editRecord, setEditRecord] = useState(null); // Record data for editing

  const [typeValidationStatus, setTypeValidationStatus] = useState("");
  const [materialValidationStatus, setMaterialValidationStatus] = useState("");
  const [jumboValidationStatus, setJumboValidationStatus] = useState("");
  const [serialValidationStatus, setSerialValidationStatus] = useState("");
  const [typeHelpMessage, setTypeHelpMessage] = useState("");
  const [Quantity, setQuantity] = useState("");
  const [jumboHelpMessage, setJumboHelpMessage] = useState("");
  const [materialHelpMessage, setMaterialHelpMessage] = useState("");
  const [serialHelpMessage, setSerialHelpMessage] = useState("");

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleNewScanner, setIsModalVisibleScanner] = useState(false);
  const [lastScannedValue, setLastScannedValue] = useState("");
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [scannerLoading, setScannerLoading] = useState("");
  const [materialName, setMaterialName] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedListOption, setSelectedListOption] = useState("1");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const [selectedOption, setSelectedOption] = useState("1"); // State to track selected option
  const [jumboOptions, setJumboOptions] = useState([]);
  const [materialOptions, setMaterialOptions] = useState([]);
  const [StoreOptions, setStoreOptions] = useState([]);
  const [labelOptions, setLabelOptions] = useState([]);
  const [ribbonOptions, setRibbonOptions] = useState([]);
  const [coreOptions, setCoreOptions] = useState([]);
  const [OrderStatusQuery, setOrderStatusQuery] = useState("");
  useScanDetection({
    // preventDefault: true,
    ignoreIfFocusOnL: {},
    onComplete: (scannedValue) => {
      const data =
        "IN001,BLD,PACKSL9,5L9,49288807,EX COEF4E.1,51224526,H0015051, HARNET PRINTING INDIA PRIVAT, PR-1,Self adhesive paper,EO3LW7820,975,E03P34360,1,214";
      // setLastScannedValue(data);
      scannedValue = scannedValue.replaceAll("Shift", "");
      handleScannedJumbo(scannedValue);
    },
    minLength: 3, // Minimum length of the scanned code
    endChar: ["Enter"], // Character(s) that signal the end of a scan
  });
  // useEffect(() => {
  //   if (isModalVisibleNewScanner) {
  //     setTimeout(() => {
  //       handleScannedJumbo(
  //         "IN001,BLD,PACKSL9,5L9,49288807,EX COEF4E.1,51224526,H0015051, HARNET PRINTING INDIA PRIVAT, JUMBOS,Self adhesive paper,EO3LW7820,975,E03P34365,1,214"
  //       );
  //     }, 2000);
  //   }
  // }, [isModalVisibleNewScanner]);
  useEffect(() => {
    // Fetch initial data
    apiCheck(
      pagination.current,
      pagination.pageSize,
      searchQuery,
      selectedListOption,
      OrderStatusQuery
    );
    // Fetch dropdown options
  }, [searchQuery]);
  const apiRefresh = () => {
    apiCheck(
      pagination.current,
      pagination.pageSize,
      searchQuery,
      selectedListOption,
      OrderStatusQuery
    );
  };
  const handleJumboTypeMaster = (value) => {
    setJumboOptions((prevOptions) => {
      let updatedOptions = [...prevOptions];
      let newObject = { jumbo_type: materialName.JumboType };
      updatedOptions.push(newObject);
      return updatedOptions;
    });
    form.resetFields();
  };

  const handleScannedJumbo = (ScannedValue) => {
    console.log("ScannedValue1", ScannedValue);
    const data = ScannedValue.split(",");
    setLastScannedValue(data);
    console.log("ScannedValue2", isModalVisibleNewScanner);
    if (isModalVisibleNewScanner && data[15]) {
      setScannerLoading("loading");
      console.log("ScannedValue1", ScannedValue);
      const data = ScannedValue.split(",");
      setLastScannedValue(data);
      console.log("ScannedValue2", data);
      ReadRecord(
        `SELECT t1.*,t2.material_name FROM jumbo_master t1 join material_master t2 ON t1.material_id=t2.id where t1.ActiveStatus=1 AND t1.jumbo_type = '${data[9].trim()}' order by t1.id DESC limit 1`
      ).then((JumboResponse) => {
        // response = response.data[0].count;
        console.log("JumboResponse", JumboResponse);
        if (JumboResponse.data[0].count > 0) {
          ReadRecord(
            `select count(*) AS count from jumbo_store where ActiveStatus = 1 and jumbo_master_id = '${
              JumboResponse.data[0].rows[0].id
            }' and jumbo_serial_no = '${data[13].trim()}'`
          ).then((StoreResponse) => {
            if (StoreResponse.data[0].rows[0].count > 0) {
              message.error("Jumbo Already Exists in Store!");
              setTimeout(() => {
                setScannerLoading("");
              }, 1000);
            } else {
              setScannerLoading("JumboMaster");
              setMaterialName({
                materialName: JumboResponse.data[0].rows[0].material_name,
                materialId: JumboResponse.data[0].rows[0].material_id,
                typeName:
                  JumboResponse.data[0].rows[0].type == "1"
                    ? "Label"
                    : "Ribbon",
                typeId: JumboResponse.data[0].rows[0].type,
                Id: JumboResponse.data[0].rows[0].id,
              });
            }
          });
        } else {
          fetchMaterialOptions();
          setScannerLoading("JumboMasterMaterialStock");
        }

        // if (JumboResponse.data[0].count > 0) {
        //   // message.success("jumbo_type and jumbo_serial_no in master");
        //   ReadRecord(
        //     `select count(*) AS count from store where type = 1 and ActiveStatus = 1 and type_id = ${JumboResponse.data[0].rows[0].id}`
        //   ).then((StoreResponse) => {
        //     if (StoreResponse.data[0].rows[0].count > 0) {
        //       message.error("Jumbo Already Exists in Store!");
        //       setTimeout(() => {
        //         setScannerLoading("");
        //       }, 1000);
        //     } else {
        //       setScannerLoading("JumboMaster");
        //       // message.success(`Stored in Store`);
        //       setMaterialName({
        //         materialName: JumboResponse.data[0].rows[0].material_name,
        //         materialId: JumboResponse.data[0].rows[0].material_id,
        //         typeName:
        //           JumboResponse.data[0].rows[0].type == "1"
        //             ? "Label"
        //             : "Ribbon",
        //         typeId: JumboResponse.data[0].rows[0].type,
        //         Id: JumboResponse.data[0].rows[0].id,
        //       });
        //     }
        //   });
        // } else {
        //   ReadRecord(
        //     `SELECT t1.jumbo_type,t2.material_name,t1.material_id,t1.type from jumbo_master t1 join material_master t2 ON t1.material_id=t2.id where t1.ActiveStatus=1 AND t1.jumbo_type = '${data[9].trim()}'`
        //   ).then((materialResponse) => {
        //     // console.log("materialResponse", materialResponse);
        //     // materialResponse=materialResponse.data[0].count
        //     if (materialResponse.data[0].count > 0) {
        //       setMaterialName({
        //         materialName: materialResponse.data[0].rows[0].material_name,
        //         materialId: materialResponse.data[0].rows[0].material_id,
        //         typeName:
        //           materialResponse.data[0].rows[0].type == "1"
        //             ? "Label"
        //             : "Ribbon",
        //         typeId: materialResponse.data[0].rows[0].type,
        //       });
        //       message.success("jumbo_type and Material available in master");
        //       setScannerLoading("JumboMaster");
        //     } else {
        //       fetchMaterialOptions();
        //       setScannerLoading("JumboMasterMaterialStock");
        //     }
        //   });
        // }
      });
    } else if (
      isModalVisibleNewScanner &&
      (data.length > 16 || data.length < 15)
    ) {
      message.error("Scan the valid QR");
    }
  };
  const handleScannedMaster = (value) => {
    if (value === "No") {
      setScannerLoading("loading");
      setTimeout(() => {
        setScannerLoading("");
      }, 1000);
      setLastScannedValue("");
      message.warning("Master not Saved");
      message.error("Stock not added");
      setMaterialName("");
      form.resetFields();
    } else {
      setScannerLoading("loading");
      try {
        // if (!materialName.Id) {
        //   const data = [
        //     {
        //       jumbo_type: lastScannedValue[9].trim(),
        //       material_id: materialName.materialId,
        //       jumbo_serial_no: lastScannedValue[13].trim(),
        //       length: Number(lastScannedValue[12]),
        //       width: Number(lastScannedValue[15]),
        //       type: materialName.typeId,
        //     },
        //   ];
        //   CreateRecord("jumboMaster", data).then((res) => {
        //     if (res.ROWID > 0) {
        //       message.success("Saved in Master!");
        //       const store = {
        //         type: 1,
        //         type_id: res.ROWID,
        //         quantity:
        //           (Number(lastScannedValue[12]) *
        //             Number(lastScannedValue[15])) /
        //           1000,
        //         store_length: Number(lastScannedValue[12]),
        //         store_width: Number(lastScannedValue[15]),
        //       };
        //       CreateRecord("store", [store]).then((res1) => {
        //         if (res1.ROWID > 0) {
        //           setScannerLoading("loading");
        //           setTimeout(() => {
        //             setScannerLoading("");
        //           }, 1000);
        //           setLastScannedValue("");
        //           setMaterialName("");
        //           form.resetFields();
        //           message.success("Jumbo Stock Added!");
        //           apiCheck(
        //             pagination.current,
        //             pagination.pageSize,
        //             searchQuery
        //           );
        //         }
        //       });
        //     }
        //   });
        // } else {
        // const data = [
        //   {
        //     jumbo_type: lastScannedValue[13].trim(),
        //     material_id: 1,
        //     jumbo_serial_no: lastScannedValue[9].trim(),
        //     length: Number(lastScannedValue[12]),
        //     width: Number(lastScannedValue[15]),
        //     type: 1,
        //   },
        // ];
        // CreateRecord("jumboMaster", data).then((res) => {
        //   if (res.ROWID > 0) {
        //     message.success("Saved in Master!");
        const JumboStore = {
          jumbo_master_id: materialName.Id,
          jumbo_serial_no: lastScannedValue[13].trim(),
          jumbo_quantity:
            (Number(lastScannedValue[12]) * Number(lastScannedValue[15])) /
            1000,
          jumbo_length: Number(lastScannedValue[15]),
          jumbo_width: Number(lastScannedValue[12]),
        };
        CreateRecord("jumboStore", [JumboStore]).then((res1) => {
          if (res1.ROWID > 0) {
            const data = [
              {
                type_id: res1.ROWID,
                type: 1,
                store_length: Number(lastScannedValue[15]),
                store_width: Number(lastScannedValue[12]),
                quantity:
                  (Number(lastScannedValue[12]) *
                    Number(lastScannedValue[15])) /
                  1000,
              },
            ];
            CreateRecord("store", data).then((fess) => {
              console.log("fess", fess);

              // setScannerLoading("loading");
              // setTimeout(() => {
              setScannerLoading("");
              // }, 1000);
              setLastScannedValue("");
              setMaterialName("");
              message.success("Jumbo Stock Added!");
              form.resetFields();
              apiRefresh();
            });
          }
        });
        // }
        // }

        // });
      } catch (error) {
        message.error("Error in saving!");
        console.error("error", error);
      }
    }
  };
  const handleJumboMasterMaterialStock = async (value) => {
    if ("Yes") {
      form.validateFields();
      let type = form.getFieldValue("type");
      let material_id = form.getFieldValue("material_id");
      console.log("lastScannedValue", lastScannedValue);
      if (
        type === undefined ||
        type === "" ||
        material_id === undefined ||
        material_id === ""
      ) {
        return;
      } else {
        try {
          const jumboMasterData = [
            {
              jumbo_type: lastScannedValue[9].trim(),
              jumbo_serial_no: lastScannedValue[13].trim(),
              material_id: material_id,
              type: type,
              length: Number(lastScannedValue[15]),
              width: Number(lastScannedValue[12]),
            },
          ];
          await CreateRecord("jumboMaster", jumboMasterData).then(
            async (response) => {
              console.log("response", response);
              if (response.ROWID > 0) {
                const jumboSerialData = [
                  {
                    jumbo_master_id: response.ROWID,
                    jumbo_serial_no: lastScannedValue[13].trim(),
                    jumbo_length: Number(lastScannedValue[15]),
                    jumbo_width: Number(lastScannedValue[12]),
                    jumbo_quantity:
                      (Number(lastScannedValue[12]) *
                        Number(lastScannedValue[15])) /
                      1000,
                  },
                ];
                await CreateRecord("jumboStore", jumboSerialData).then(
                  async (jumboSerialResponse) => {
                    if (jumboSerialResponse.ROWID > 0) {
                      const Jumbostore = [
                        {
                          type_id: jumboSerialResponse.ROWID,
                          type: 1,
                          store_length: Number(lastScannedValue[15]),
                          store_width: Number(lastScannedValue[12]),
                          quantity:
                            (Number(lastScannedValue[12]) *
                              Number(lastScannedValue[15])) /
                            1000,
                        },
                      ];
                      await CreateRecord("store", Jumbostore).then(
                        (storeResponse) => {
                          setScannerLoading("");
                          setLastScannedValue("");
                          message.success("Master Added!");
                          message.success("Jumbo Stock Added!");
                          form.resetFields();
                          apiRefresh();
                        }
                      );
                    }
                  }
                );
              }
            }
          );
        } catch (error) {
          console.error(error);
          message.error("Error in saving!");
        }
      }
    } else {
      setLastScannedValue("");
      message.warning("Master not Saved");
      message.error("Stock not added");
      setMaterialName("");
      form.resetFields();
    }
  };

  async function apiCheck(
    page,
    pageSize,
    search = "",
    selectedOption = "1",
    query = ""
  ) {
    let dateFilter = "";
    let searchFilter = "";
    let offset = (page - 1) * pageSize;

    if (search !== "") {
      switch (selectedOption) {
        case "1":
          searchFilter = `AND (t3.jumbo_type LIKE '%${search.trim()}%' OR t1.quantity LIKE '%${search.trim()}%' OR t2.jumbo_serial_no LIKE '%${search.trim()}%')`;
          break;
        case "2":
          searchFilter = `AND (t2.other_material_name LIKE '%${search.trim()}%' OR t1.quantity LIKE '%${search.trim()}%')`;
          break;
        case "3":
          searchFilter = `AND (concat(t2.label_width, " x ", t2.label_length, " ",t2.label_measure) like '%${search.trim()}%'  OR t1.quantity LIKE '%${search.trim()}%')`;
          break;
        case "4":
          searchFilter = `AND (t2.core_size LIKE '%${search.trim()}%' OR t2.core_type LIKE '%${search.trim()}' OR t1.quantity LIKE '%${search.trim()}%')`;
          break;
        case "5":
          searchFilter = `AND (concat(t2.ribbon_width, " x ", t2.ribbon_length, " ",t2.ribbon_measure) like '%${search.trim()}%'  OR t1.quantity LIKE '%${search.trim()}%')`;
          break;
        default:
          break;
      }
      offset = 0;
    }

    let selectClause = "";
    let joinClause = "";
    let filterClause = "";

    switch (selectedOption) {
      case "1":
        selectClause =
          "t2.jumbo_serial_no,t3.jumbo_type AS type_name,t3.type AS jumbo_type_name ,t1.store_length AS length,t1.store_width AS width,t4.material_name,t3.type AS type_name2 ";
        joinClause =
          " join jumbo_store t2 ON t1.type_id=t2.id join jumbo_master t3 ON t2.jumbo_master_id=t3.id join material_master t4 ON t3.material_id=t4.id";
        filterClause = "t1.type = 1";
        break;
      case "2":
        selectClause = "t2.other_material_name as type_name";
        joinClause = "JOIN other_materials t2 ON t1.type_id = t2.id";
        filterClause = "t1.type = 2";
        break;
      case "3":
        selectClause =
          "CONCAT(t2.label_width, ' x ', t2.label_length,' ', t2.label_measure) as type_name";
        joinClause = "JOIN label_master t2 ON t1.type_id = t2.id";
        filterClause = "t1.type = 3";
        break;
      case "4":
        selectClause =
          "CONCAT(t2.core_type, ' - ',t2.core_dia, ' x ',t2.core_dia_measure,' x ',t2.core_length,' ',t2.core_length_measure) as type_name,t2.core_type";
        joinClause = "JOIN core_master t2 ON t1.type_id = t2.id";
        filterClause = "t1.type = 4";
        break;
      case "5":
        selectClause =
          "CONCAT(t2.ribbon_width, ' x ', t2.ribbon_length,' ',  t2.ribbon_measure) as type_name";
        joinClause = "JOIN ribbon_master t2 ON t1.type_id = t2.id";
        filterClause = "t1.type = 5";
        break;
      default:
        break;
    }

    let searchData = `
      SELECT t1.*,CAST(ROUND(t1.quantity, 2) AS CHAR) as quantity,concat(t1.store_width, " x ", t1.store_length) as store_size, ${selectClause}
      FROM store t1
      ${joinClause}
      WHERE t1.status = 1 AND t1.ActiveStatus = 1 AND
       ${filterClause} ${searchFilter} ${query}
      ORDER BY t1.id desc
      LIMIT ${pageSize} OFFSET ${offset}
    `;

    let searchDataCount = `
      SELECT COUNT(*) as TotalCount
      FROM store t1
      ${joinClause}
      WHERE t1.status = 1 AND t1.ActiveStatus = 1 AND ${filterClause} ${searchFilter} ${query}
    `;

    const apiRequest1 = ReadRecord(searchData);
    const apiRequest2 = ReadRecord(searchDataCount);

    try {
      const [data1, data2] = await Promise.all([apiRequest1, apiRequest2]);

      setStoreData(data1.data[0]?.rows || []);
      let total = data2.data[0]?.rows[0]?.TotalCount || 0;

      setPagination((prevPagination) => ({
        ...prevPagination,
        current: page,
        pageSize: pageSize,
        total: total,
      }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  // Function to fetch Jumbo options
  const fetchJumboOptions = async () => {
    try {
      const response = await ReadRecord(
        `SELECT id,jumbo_type FROM jumbo_master WHERE status = 1 and ActiveStatus = 1 order by id desc;`
      );
      setJumboOptions(response.data[0]?.rows || []);
    } catch (error) {
      console.error("Error fetching Jumbo options:", error);
    }
  };

  // Function to fetch Material options
  const fetchMaterialOptions = async () => {
    try {
      const response = await ReadRecord(
        `SELECT id,material_name FROM material_master WHERE status = 1 and ActiveStatus = 1`
      );
      setMaterialOptions(response.data[0]?.rows || []);
    } catch (error) {
      console.error("Error fetching Material options:", error);
    }
  };
  const fetchStoreOptions = async () => {
    try {
      const response = await ReadRecord(
        `SELECT distinct concat(store_width, " x ", store_length) as store_size FROM store WHERE status = 1 and ActiveStatus = 1`
      );
      setStoreOptions(response.data[0]?.rows || []);
    } catch (error) {
      console.error("Error fetching Store options:", error);
    }
  };

  // Function to fetch Label options
  const fetchLabelOptions = async () => {
    try {
      const response = await ReadRecord(
        `SELECT id,label_length,label_width,label_measure FROM label_master WHERE status = 1 and ActiveStatus = 1`
      );
      setLabelOptions(response.data[0]?.rows || []);
    } catch (error) {
      console.error("Error fetching Label options:", error);
    }
  };
  const fetchRibbonOptions = async () => {
    try {
      const response = await ReadRecord(
        `SELECT id,ribbon_length,ribbon_width,ribbon_measure FROM ribbon_master WHERE status = 1 and ActiveStatus = 1`
      );
      setRibbonOptions(response.data[0]?.rows || []);
    } catch (error) {
      console.error("Error fetching Label options:", error);
    }
  };

  // Function to fetch Core options
  const fetchCoreOptions = async () => {
    try {
      const response = await ReadRecord(
        `SELECT id,core_type,core_size,core_dia,core_dia_measure,core_length,core_length_measure FROM core_master WHERE status = 1 and ActiveStatus = 1 order by id desc`
      );
      setCoreOptions(response.data[0]?.rows || []);
    } catch (error) {
      console.error("Error fetching Core options:", error);
    }
  };

  // Function to handle form submission
  const onFinish = async (values) => {
    if (selectedListOption === "1" || selectedOption === "1") {
      form.validateFields();
      if (
        values.type === "Select the type" ||
        values.type === "" ||
        values.type === undefined
      ) {
        setTypeValidationStatus("error");
        setTypeHelpMessage("Please select the value");
        return;
      } else if (
        values.material_id === "Select the Material" ||
        values.material_id === "" ||
        values.material_id === undefined
      ) {
        setMaterialValidationStatus("error");
        setMaterialHelpMessage("Please select the value");
        return;
      } else if (
        values.jumbo_serial_no === "" ||
        values.jumbo_serial_no === undefined
      ) {
        setSerialValidationStatus("error");
        setSerialHelpMessage("Please Enter Jumbo Serial!");
        return;
      } else {
        setTypeValidationStatus("");
        setTypeHelpMessage("");
        setMaterialValidationStatus("");
        setMaterialHelpMessage("");
        setSerialValidationStatus("");
        setSerialHelpMessage("");

        try {
          const response = await ReadRecord(
            `SELECT count(*) AS count FROM jumbo_store WHERE jumbo_master_id='${values.type_id}' AND jumbo_serial_no = '${values.jumbo_serial_no}' AND ActiveStatus = 1`
          );

          const count = response.data[0].rows[0].count;
          console.log("response", count);

          if (count > 0) {
            setSerialValidationStatus("error");
            setSerialHelpMessage("Serial already exists with the same Jumbo!");
          } else {
            const quantity =
              (Number(values.length) * Number(values.width)) / 1000;
            const data = [
              {
                jumbo_master_id: Number(values.type_id),
                jumbo_serial_no: values.jumbo_serial_no,
                jumbo_length: values.length,
                jumbo_width: values.width,
                jumbo_quantity: quantity,
              },
            ];

            const res = await CreateRecord("jumboStore", data);
            console.log("res", res);
            if (res.ROWID > 0) {
              const storeData = [
                {
                  type_id: res.ROWID,
                  type: 1,
                  store_length: values.length,
                  store_width: values.width,
                  quantity: quantity,
                },
              ];
              CreateRecord("store", storeData).then((fess) => {
                console.log("fess", fess);
                message.success("Jumbo Stored");
                form.resetFields();
                setSerialValidationStatus("");
                setSerialHelpMessage("");
                apiRefresh();
              });
            }
          }
        } catch (error) {
          message.error("Issue in saving, Please refresh!");
          console.error(error);
        }
      }
    } else if (selectedListOption === "2" || selectedOption === "2") {
      console.log("values", values);
      // const values.other_material_name = form.getFieldValue("other_material_name");
      if (
        values.other_material_name === undefined ||
        values.other_material_name == "" ||
        values.quantity === undefined ||
        values.quantity == ""
      ) {
        form.validateFields();
        setJumboValidationStatus("warning");
        setJumboHelpMessage("Enter Quantity");
        return;
      }
      const response = await ReadRecord(
        `SELECT count(*) AS count FROM other_materials WHERE other_material_name = '${values.other_material_name}' and ActiveStatus = 1 and Status = 1`
      );
      const count = response?.data[0]?.rows[0]?.count;
      if (count > 0) {
        message.error("Already Exists in Store!");
      } else {
        try {
          CreateRecord("other_materials", [
            {
              other_material_name: values.other_material_name,
              quantity: values.quantity,
            },
          ]).then((response) => {
            console.log("response", response);
            if (response.ROWID > 0) {
              CreateRecord("store", [
                {
                  type_id: response.ROWID,
                  type: 2,
                  quantity: values.quantity,
                },
              ]).then((res) => {
                message.success("Material Stored!");
                setJumboValidationStatus("");
                setJumboHelpMessage("");
                form.resetFields();
                apiRefresh();
              });
            } else {
            }
          });
        } catch (error) {
          console.error(error);
          message.error("Error please Reload the Page!");
        }
      }
    } else {
      console.log("values", values);
      if (values.quantity == undefined || values.quantity == "") {
        setJumboValidationStatus("error");
        setJumboHelpMessage("please fill quantity");
        return;
      }

      const quantityPattern = /^(?!0)\d+(\.\d+)?$/;

      // Check if the quantity matches the pattern
      if (!quantityPattern.test(values.quantity)) {
        setJumboValidationStatus("error");
        setJumboHelpMessage("Please enter a valid quantity");
        return;
      }
      console.log("selectedOption", selectedOption, selectedListOption);
      if (selectedListOption || selectedOption) {
        ReadRecord(`
          SELECT COUNT(*) AS count FROM store
          WHERE type='${selectedListOption}' and type_id='${values.type_id}'
          AND (status = 1 or status=0) AND ActiveStatus=1
        `).then((res) => {
          console.log("core count", res.data[0]?.rows[0].count);
          if (res.data[0]?.rows[0].count == 0) {
            submitForm(values);
          } else {
            setJumboValidationStatus("error");
            setJumboHelpMessage(
              "This record already existing, Please update this record quantity"
            );
            return;
          }
        });
      }
    }
  };

  const submitForm = (values) => {
    console.log("valuesss", values);
    const data = {
      type: Number(selectedListOption), // Assuming selectedListOption is type
      type_id: Number(values.type_id),
      quantity: Number(values.quantity),
    };

    CreateRecord("store", [data])
      .then((res) => {
        message.success("Label Stock added");
        setIsModalVisible(false);
        setJumboHelpMessage("");
        setJumboValidationStatus("");
        form.resetFields();
        apiRefresh();
      })
      .catch((error) => {
        console.error("Error creating record:", error);
      });
  };

  // Function to handle search input change
  const handleSearch = (value) => {
    setSearchQuery(value);
  };
  const handleAddNewJumbo = () => {};
  const handleOptionSelect = (option) => {
    console.log("option",option);
    
    setSelectedOption(option);
    setSelectedListOption(option);
    form.resetFields("");
    setIsModalVisible(true);
  };
  useEffect(() => {
    fetchMaterialOptions();
    fetchStoreOptions();
  }, []);
  useEffect(() => {
    console.log("filteredInfo", filteredInfo);
  }, [filteredInfo]);
  // Function to handle option list select
  const handleOptionListSelect = (option) => {
    setSelectedListOption(option);
    // form.setFieldValue({ "type": option });
    // form.resetFields();
    if (option != 1) {
      apiCheck(1, pagination.pageSize, searchQuery, option);
    } else {
      apiCheck(1, pagination.pageSize, searchQuery, option, OrderStatusQuery);
    }
  };

  // Modal functions
  const showModal = () => {
    form.resetFields();
    setSelectedOption(selectedListOption);
    setIsModalVisible(true);
    fetchJumboOptions();

    fetchLabelOptions();
    fetchRibbonOptions();
    fetchCoreOptions();
  };
  const showModalScanner = () => {
    setIsModalVisibleScanner(true);
  };

  const handleCancel = () => {
    setSelectedOption("");
    setIsModalVisible(false);
    setJumboHelpMessage("");
    setJumboValidationStatus("");
    form.resetFields();
    setMaterialName("");
  };

  const handleCancelNew = () => {
    setSelectedOption("");
    setModalVisible(false);
    setJumboHelpMessage("");
    setJumboValidationStatus("");
    setMaterialName("");
    form.resetFields();
  };
  const handleCancelScanner = () => {
    setIsModalVisibleScanner(false);
    setLastScannedValue("");
    setScannerLoading("");
    setMaterialName("");
  };
  const handleView = (record) => {
    form.setFieldValue("id", record.id);
    form.setFieldValue("type_name", record.type_name);
    form.setFieldValue("quantity", record.quantity);
    form.setFieldValue("reserved_quantity", record.reserved_quantity);
    if (record.type === "1") {
      form.setFieldsValue(record);
      const type_name2 = record.type_name2 == "1" ? "Label" : "Ribbon";
      form.setFieldValue("type_name2", type_name2);
    } else if (record.type === "2") {
      form.setFieldValue("material_type", record.material_type);
    } else if (record.type === "3") {
      form.setFieldValue("ups", record.ups);
    } else if (record.type == "4") {
      form.setFieldValue("core_type", record.core_type);
    } else if (record.type == "5") {
      // form.setFieldValue("core_type", record.core_type);
    }
    setModalVisible(true);
    setMode("view");
  };
  const handleUpdate = (record) => {
    console.log("record", record);
    let wuantity = Number(record.quantity) + Number(Quantity);
    console.log("Quantity", wuantity);

    UpdateRecord("store", [
      {
        id: record.id,
        quantity: wuantity,
      },
    ]).then((res) => {
      setQuantity("");
      apiRefresh();
    });
  };

  const handleEdit = (record) => {
    form.setFieldValue("id", record.id);
    form.setFieldValue("type_name", record.type_name);
    form.setFieldValue("quantity", record.quantity);
    form.setFieldValue("reserved_quantity", record.reserved_quantity);
    if (record.type === "1") {
      form.setFieldsValue(record);
      form.setFieldValue("jumbo_serial_no", record.jumbo_serial_no);
      const type_name2 = record.type_name2 == "1" ? "Label" : "Ribbon";
      form.setFieldValue("type_name2", type_name2);
    } else if (record.type === "2") {
      form.setFieldValue("material_type", record.material_type);
    } else if (record.type === "3") {
      form.setFieldValue("ups", record.ups);
    } else if (record.type == "4") {
      form.setFieldValue("core_type", record.core_type);
    }
    setModalVisible(true);
    setMode("edit");
  };

  const onEditFinish = () => {
    let values = form.getFieldsValue("quantity");

    console.log("editing quantity", values.quantity);
    if (values.quantity == undefined || values.quantity == "") {
      setJumboValidationStatus("error");
      setJumboHelpMessage("please fill quantity");
      return;
    }
    const quantityPattern = /^(?!0)\d+(\.\d+)?$/;
    // Check if the quantity matches the pattern
    if (!quantityPattern.test(values.quantity)) {
      setJumboValidationStatus("error");
      setJumboHelpMessage("Please enter a valid quantity");
      return;
    }
    EditFormSubmit();
  };

  const EditFormSubmit = () => {
    let data = "";
    if (selectedListOption === "1") {
      data = {
        id: form.getFieldValue("id"),
        quantity: form.getFieldValue("quantity"),
        store_length: Number(form.getFieldValue("length")),
        store_width: Number(form.getFieldValue("width")),
      };
    } else {
      data = {
        id: form.getFieldValue("id"),
        quantity: form.getFieldValue("quantity"),
      };
    }

    console.log("data", data);

    const updateData = UpdateRecord("store_quantity", [data]).then((res) => {
      console.log("update response", res);
      apiRefresh();
    });
    setMode("view"); // Reset mode to 'view'
    setEditRecord(""); // Clear editRecord state
    message.success("Stock updated successfully");
    setModalVisible(false);
    setJumboHelpMessage("");
    setJumboValidationStatus("");
  };

  const handleDelete = (record) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this record?"
    );
    if (isConfirmed) {
      console.log("Before deleting record", record.id);
      let data = {
        id: record.id,
        status: false,
        ActiveStatus: false,
      };
      UpdateRecord("deleteStore", [data]).then((res) => {
        //userListData();
        apiRefresh();
        message.info("Stock record has been deleted");
      });
    }
  };
  const handleJumboTypeChange = async (value) => {
    try {
      const res = await ReadRecord(
        `SELECT t1.id,t1.material_id,t1.type,t2.material_name FROM jumbo_master t1 join material_master t2 ON t1.material_id=t2.id where t1.id='${value}' order by t1.id desc limit 1`
      );
      console.log("created by value", res.data[0].rows[0]);

      if (res.data[0].count > 0) {
        setMaterialName({
          materialName: res.data[0].rows[0].material_name || "false",
          materialId: res.data[0].rows[0].material_id || "false",
          typeName:
            res.data[0].rows[0].type == "1"
              ? "Label"
              : res.data[0].rows[0].type == "2"
              ? "Ribbon"
              : "false",
          typeId: res.data[0].rows[0].type || "false",
          Id: res.data[0].rows[0].id || "false",
        });
        // setSelectedJumboType(res.data[0].rows[0].jumbo_serial_no);
        form.setFieldValue(
          "type",
          res.data[0].rows[0].type == "1"
            ? "Label"
            : res.data[0].rows[0].type == "2"
            ? "Ribbon"
            : "Select the type"
        );
        form.setFieldValue(
          "material_id",
          res.data[0].rows[0].material_name || "Select the Material"
        );
      } else {
        setMaterialName({
          materialName: "false",
          materialId: "false",
          typeName: "false",
          typeId: "false",
          Id: "false",
        });
        form.setFieldValue("type", "Select the type");
        form.setFieldValue("material_id", "Select the Material");
      }
    } catch (error) {
      console.error("Error fetching jumbo data:", error);
    }
  };
  const handleMaterialTypeChange = async (value) => {
    try {
      const res = await ReadRecord(
        `select material_name from material_master where id='${value}'`
      );
      // setSelectedJumboType(res.data[0].rows[0].jumbo_serial_no);
      form.setFieldValue("material_name", res.data[0].rows[0].material_name);
    } catch (error) {
      console.error("Error fetching material_name data:", error);
    }
  };
  const handleLabelTypeChange = async (value) => {
    try {
      const res = await ReadRecord(
        `select ups from label_master where id='${value}'`
      );
      // setSelectedJumboType(res.data[0].rows[0].jumbo_serial_no);
      form.setFieldValue("ups", res.data[0].rows[0].ups);
    } catch (error) {
      console.error("Error fetching ups data:", error);
    }
  };
  const handleCoreTypeChange = async (value) => {
    try {
      const res = await ReadRecord(
        `select core_type from core_master where id='${value}'`
      );
      // setSelectedJumboType(res.data[0].rows[0].jumbo_serial_no);
      form.setFieldValue("core_type", res.data[0].rows[0].core_type);
    } catch (error) {
      console.error("Error fetching core_type data:", error);
    }
  };
  const clearFilters = () => {
    setFilteredInfo({});
    setSearchQuery("");
    setOrderStatusQuery("");
    setTimeout(() => {
      apiCheck(pagination.current, pagination.pageSize, "", selectedListOption);
    }, 0);
  };
  const handleTableChange = (pagination, filters) => {
    console.log("filters", filters);
    let query = "";
    const filterClauses = [];
    if (
      filters?.Measurements != null ||
      filters?.jumbo_status != null ||
      filters?.jumbo_type_name != null ||
      filters?.material_name != null
    ) {
      setFilteredInfo(filters);
    } else {
      setFilteredInfo({});
    }
    // Check and add clause for jumbo_status
    if (filters.jumbo_status && filters.jumbo_status.length > 0) {
      const orderStatusInClause = filters.jumbo_status
        .filter((item) => item) // Filter out empty or invalid values
        .map((item) => `'${item}'`)
        .join(", ");
      if (orderStatusInClause) {
        filterClauses.push(`t1.jumbo_status IN (${orderStatusInClause})`);
      }
    }

    // Check and add clause for material_name
    if (filters.material_name && filters.material_name.length > 0) {
      const materialNameInClause = filters.material_name
        .filter((item) => item)
        .map((item) => `'${item}'`)
        .join(", ");
      if (materialNameInClause) {
        filterClauses.push(`t4.material_name IN (${materialNameInClause})`);
      }
    }

    // Check and add clause for jumbo_type_name
    if (filters.jumbo_type_name && filters.jumbo_type_name.length > 0) {
      const typeNameInClause = filters.jumbo_type_name
        .filter((item) => item)
        .map((item) => `'${item}'`)
        .join(", ");
      if (typeNameInClause) {
        filterClauses.push(`t3.type IN (${typeNameInClause})`);
      }
    }

    // Check and add clause for Measurements
    if (filters.Measurements && filters.Measurements.length > 0) {
      const MeasurementsInClause = filters.Measurements.filter((item) => item)
        .map((item) => `'${item}'`)
        .join(", ");
      if (MeasurementsInClause) {
        filterClauses.push(
          `concat(t1.store_width, " x ", t1.store_length) IN (${MeasurementsInClause})`
        );
      }
    }

    // Join all filter clauses with AND
    if (filterClauses.length > 0) {
      query = `AND ${filterClauses.join(" AND ")}`;
    }

    // Update the query state
    setOrderStatusQuery(query);

    // Trigger the API check with pagination and filters
    return apiCheck(
      pagination.current,
      pagination.pageSize,
      searchQuery,
      selectedListOption,
      query
    );
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button> */}
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  return (
    <div>
      {/* Loading indicator */}
      {isLoading ? (
        <LoadingOutlined>Loading...</LoadingOutlined>
      ) : (
        <>
          {/* Header with search and add button */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <h2 style={{ color: "#333", marginRight: "auto" }}>STOCK</h2>
            {filteredInfo && Object.keys(filteredInfo).length > 0 && (
              <Button
                onClick={clearFilters}
                style={{ borderColor: "red", color: "red" }}
              >
                Clear Filters
              </Button>
            )}
            &nbsp;
            <Select
              defaultValue="1"
              onChange={handleOptionListSelect}
              style={{ minWidth: "300px" }}
              placeholder="Please select an option to view list"
            >
              <Select.Option value="1">Jumbo</Select.Option>
              <Select.Option value="2">Other Materials</Select.Option>
              <Select.Option value="3">Label</Select.Option>
              <Select.Option value="4">Core</Select.Option>
              <Select.Option value="5">Ribbon</Select.Option>
            </Select>
            <Input
              placeholder="Search"
              value={searchQuery}
              allowClear
              style={{ width: 350, margin: "0 4px" }}
              prefix={<SearchOutlined />}
              onChange={(e) => handleSearch(e.target.value)}
            />
            {(harnetRoleAccess && harnetRoleAccess.includes("9")) ||
            (userRoleHarnet && userRoleHarnet === "1") ? (
              <Tooltip title="Scan to add Jumbo Stock">
                <Button
                  style={{ marginRight: "5px" }}
                  type="default"
                  onClick={showModalScanner}
                  icon={<BsQrCodeScan size={20} />}
                ></Button>
              </Tooltip>
            ) : (
              ""
            )}
            {(harnetRoleAccess && harnetRoleAccess.includes("4")) ||
            (userRoleHarnet && userRoleHarnet === "1") ? (
              <Button
                type="primary"
                onClick={showModal}
                icon={<PlusOutlined />}
              >
                Add Stock
              </Button>
            ) : (
              ""
            )}
          </div>

          {/* Table to display stock data */}
          <Table
            onChange={handleTableChange}
            size="small"
            bordered
            dataSource={storeData}
            columns={[
              {
                title: "S.No.",
                dataIndex: "serial",
                key: "serial",
                align: "center",
                render: (text, record, index) =>
                  index + (pagination.current - 1) * pagination.pageSize + 1,
              },
              {
                defaultValue: "1",
                title:
                  selectedListOption === "1"
                    ? "Jumbo"
                    : selectedListOption === "2"
                    ? "Material Name"
                    : selectedListOption === "3"
                    ? "Label Size"
                    : selectedListOption === "4"
                    ? "Core Size"
                    : selectedListOption === "5"
                    ? "Ribbon Size"
                    : "Type Name",
                dataIndex: "type_name",
                key: "type_name",
                align: "center",
              },
              selectedListOption === "1" && {
                defaultValue: "1",
                title: "Jumbo Date",
                dataIndex: "CreatedDate",
                key: "CreatedDate",
                align: "center",
              },
              selectedListOption === "1" && {
                title: "Jumbo Serial No",
                dataIndex: "jumbo_serial_no",
                key: "new1",
                align: "center",
              },
              selectedListOption === "1" && {
                title: "Material",
                dataIndex: "material_name",
                key: "material_name",
                align: "center",
                filters: materialOptions.map((item) => ({
                  text: item.material_name,
                  value: item.material_name,
                })),
                filteredValue: filteredInfo.material_name || null,
                onFilter: (value, record) => record.material_name === value,
              },

              selectedListOption === "1" && {
                title: "Type",
                dataIndex: "jumbo_type_name",
                key: "jumbo_type_name",
                align: "center",
                filters: [
                  { text: "Label", value: "1" },
                  { text: "Ribbon", value: "2" },
                ],
                filteredValue: filteredInfo.jumbo_type_name || null,
                onFilter: (value, record) => record.jumbo_type_name === value,
                render: (type) => (
                  <span>{type == 1 ? "Label" : type == 2 ? "Ribbon" : ""}</span>
                ),
              },

              // selectedListOption === "2" && {
              //   title: "Material Type",
              //   dataIndex: "material_type",
              //   key: "material_type",
              //   align: "center",
              // },
              // // selectedListOption === "3" && {
              //   title: "Unit per size",
              //   dataIndex: "ups",
              //   key: "new2",
              //   align: "center",
              // },
              // selectedListOption === "4" && {
              //   title: "Core Type",
              //   dataIndex: "core_type",
              //   key: "new3",
              //   align: "center",
              // },
              selectedListOption === "1" && {
                title: "Measurements",
                dataIndex: "store_size",
                key: "Measurements",
                align: "center",
                // ...getColumnSearchProps("pro_code"),
                filters: StoreOptions.map((item) => ({
                  text: item.store_size,
                  value: item.store_size,
                })),
                filteredValue: filteredInfo.Measurements || null,
                onFilter: (value, record) => record.store_size === value,
                // render: (text, record) => (
                //   <span>
                //     {record.store_width + " x " + record.store_length}
                //   </span>
                // ),
              },
              {
                title: "Stock Quantity",
                dataIndex: "quantity",
                key: "quantity",
                align: "center",
              },
              selectedListOption != "1" &&
                selectedListOption != "2" && {
                  title: "Reserved Quantity",
                  dataIndex: "reserved_quantity",
                  key: "quantity2",
                  align: "center",
                },
              selectedListOption === "1" && {
                title: "Jumbo Status",
                dataIndex: "jumbo_status",
                key: "jumbo_status",
                align: "center",
                filters: [
                  { text: "In Production", value: "In Production" },
                  { text: "No Stock", value: "No Stock" },
                  { text: "Available", value: "Available" },
                ],
                filteredValue: filteredInfo.jumbo_status || null,
                onFilter: (value, record) => record.jumbo_status === value,
                render: (jumbo_status) => {
                  let color;
                  let text;
                  switch (jumbo_status) {
                    case "In Production":
                      color = "orange";
                      break;
                    case "No Stock":
                      color = "brown";
                      break;
                    case "Available":
                      color = "green";
                      break;
                    default:
                      color = "blue";
                      break;
                  }
                  return <Tag color={color}>{jumbo_status}</Tag>;
                },
              },
              {
                title: "Actions",
                dataIndex: "Actions",
                key: "Actions",
                align: "center",
                width: 200,
                fixed: "right",
                render: (text, record) => (
                  <>
                    {(harnetRoleAccess && harnetRoleAccess.includes("8")) ||
                    (userRoleHarnet && userRoleHarnet === "1")
                      ? selectedListOption != "1" &&
                        selectedListOption != "2" && (
                          <>
                            <Tooltip title="Add Quantity" placement="left">
                              <Popconfirm
                                title={`Add Extra Quantity for (${record.type_name})`}
                                onConfirm={() => handleUpdate(record)}
                                okText="Yes"
                                cancelText="No"
                                description={
                                  <span>
                                    <Input
                                      autoComplete="off"
                                      value={Quantity}
                                      type="number"
                                      onChange={(e) => {
                                        setQuantity(e.target.value);
                                      }}
                                    />
                                  </span>
                                }
                              >
                                <Button
                                  icon={<PlusOutlined />}
                                  type="primary"
                                  style={{ backgroundColor: "green" }}
                                ></Button>
                              </Popconfirm>
                            </Tooltip>
                            &nbsp;
                          </>
                        )
                      : null}
                    {(harnetRoleAccess && harnetRoleAccess.includes("5")) ||
                    (userRoleHarnet && userRoleHarnet === "1") ? (
                      <Button
                        icon={<EyeOutlined />}
                        type="primary"
                        style={{ backgroundColor: "#be991d" }}
                        title="View"
                        onClick={() => handleView(record)}
                      ></Button>
                    ) : (
                      ""
                    )}
                    &nbsp;
                    {(harnetRoleAccess && harnetRoleAccess.includes("6")) ||
                    (userRoleHarnet && userRoleHarnet === "1") ? (
                      <Button
                        icon={<EditOutlined />}
                        style={{ backgroundColor: "#005c9f" }}
                        type="primary"
                        title="Edit"
                        onClick={() => handleEdit(record)}
                        disabled={
                          record.reserved_quantity > 0 ||
                          record.jumbo_status == "In Production" ||
                          record.jumbo_status == "No Stock"
                        }
                      ></Button>
                    ) : (
                      ""
                    )}
                    &nbsp;
                    {(harnetRoleAccess && harnetRoleAccess.includes("7")) ||
                    (userRoleHarnet && userRoleHarnet === "1") ? (
                      <Button
                        icon={<DeleteOutlined />}
                        type="primary"
                        style={{ backgroundColor: "#d7524b" }}
                        title="Delete"
                        onClick={() => handleDelete(record)}
                        disabled={
                          record.reserved_quantity > 0 ||
                          record.jumbo_status == "In Production" ||
                          record.jumbo_status == "No Stock"
                        }
                      ></Button>
                    ) : (
                      ""
                    )}
                  </>
                ),
              },
            ].filter(Boolean)}
            rowClassName={(record, index) =>
              index % 2 === 1 ? "even-row" : "odd-row"
            }
            pagination={{
              pageSize: pagination.pageSize,
              // onChange: (page, pageSize) =>
              //   apiCheck(page, pageSize, searchQuery),
              total: pagination.total,
              showSizeChanger: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
            scroll={{
              x: "max-content",
            }}
          />
        </>
      )}

      <Modal
        centered
        title={mode === "edit" ? "Edit Record" : "View Record"}
        open={modalVisible}
        onCancel={handleCancelNew}
        footer={null}
        maskClosable={false}
      >
        <Form
          form={form}
          // onFinish={onEditFinish}
        >
          {
            <>
              {selectedListOption === "1" && (
                <>
                  <Row gutter={16} align="middle">
                    <Col span={22}>
                      <Form.Item label="Jumbo Type" name="type_name">
                        <Input disabled={mode === "view" || mode === "edit"} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        label="Jumbo serial"
                        name="jumbo_serial_no"
                        id="jumboserialno"
                        className="jumboserialno"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Jumbo Serial!",
                          },
                        ]}
                      >
                        <Input disabled={mode === "view" || mode === "edit"} />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row
                    gutter={16}
                    align="middle"
                    // style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Col span={12}>
                      <Form.Item label="Material" name="material_name">
                        <Input disabled={mode === "view" || mode === "edit"} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Width"
                        name="width"
                        id="jumbolength"
                        className="jumbolength"
                      >
                        <Input autoComplete="off" disabled={mode === "view"} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="Type" name="type_name2">
                        <Input disabled={mode === "view" || mode === "edit"} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Length"
                        name="length"
                        id="jumbolength"
                        className="jumbolength"
                      >
                        <Input autoComplete="off" disabled={mode === "view"} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="Quantity" name="quantity">
                        <Input disabled={mode === "view"} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Status"
                        name="jumbo_status"
                        id="jumbo_status"
                        className="jumbo_status"
                      >
                        <Input disabled={mode === "view" || mode === "edit"} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Wastage"
                        name="jumbo_wastage"
                        id="jumbo_wastage"
                        className="jumbo_wastage"
                      >
                        <Input disabled={mode === "view" || mode === "edit"} />
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    label="Quantity"
                    name="quantity"
                    rules={[
                      { required: true, message: "Please enter Quantity!" },
                    ]}
                  >
                    <InputNumber autoComplete="off" min={1} />
                  </Form.Item>
                </Col>
              </Row> */}
                </>
              )}

              {selectedListOption === "2" && (
                <>
                  <Form.Item label="ID" name="id" style={{ display: "none" }}>
                    <Input disabled />
                  </Form.Item>
                  <Form.Item label="Material Name" name="type_name">
                    <Input disabled={mode === "view" || mode === "edit"} />
                  </Form.Item>
                  <Form.Item label="Quantity" name="quantity">
                    <Input disabled={mode === "view"} />
                  </Form.Item>
                  {/* <Form.Item label="Reserved Quantity" name="reserved_quantity">
                    <Input disabled />
                  </Form.Item> */}
                  {/* <Form.Item
                    label="Quantity"
                    name="quantity"
                    hasFeedback
                    validateStatus={jumboValidationStatus}
                    help={jumboHelpMessage}
                  >
                    <Input disabled={mode === "view"} />
                  </Form.Item> */}
                </>
              )}

              {selectedListOption === "3" && (
                <>
                  <Form.Item label="ID" name="id" style={{ display: "none" }}>
                    <Input disabled />
                  </Form.Item>
                  <Form.Item label="Label Size" name="type_name">
                    <Input disabled />
                  </Form.Item>
                  <Form.Item
                    label="Quantity"
                    name="quantity"
                    hasFeedback
                    validateStatus={jumboValidationStatus}
                    help={jumboHelpMessage}
                  >
                    <Input disabled={mode === "view"} />
                  </Form.Item>
                  <Form.Item
                    label="Reserved"
                    name="reserved_quantity"
                    hasFeedback
                    validateStatus={jumboValidationStatus}
                    help={jumboHelpMessage}
                  >
                    <Input disabled={mode === "view" || mode === "edit"} />
                  </Form.Item>
                </>
              )}

              {selectedListOption === "4" && (
                <>
                  <Form.Item label="ID" name="id" style={{ display: "none" }}>
                    <Input disabled />
                  </Form.Item>
                  <Form.Item label="Core Size" name="type_name">
                    <Input disabled />
                  </Form.Item>
                  {/* <Form.Item label="Core Type" name="core_type">
                    <Input disabled />
                  </Form.Item> */}
                  <Form.Item
                    label="Quantity"
                    name="quantity"
                    hasFeedback
                    validateStatus={jumboValidationStatus}
                    help={jumboHelpMessage}
                  >
                    <Input disabled={mode === "view"} />
                  </Form.Item>
                </>
              )}
              {selectedListOption === "5" && (
                <>
                  <Form.Item label="ID" name="id" style={{ display: "none" }}>
                    <Input disabled />
                  </Form.Item>
                  <Form.Item label="Label Size" name="type_name">
                    <Input disabled />
                  </Form.Item>
                  <Form.Item
                    label="Quantity"
                    name="quantity"
                    hasFeedback
                    validateStatus={jumboValidationStatus}
                    help={jumboHelpMessage}
                  >
                    <Input disabled={mode === "view"} />
                  </Form.Item>
                  <Form.Item
                    label="Reserved"
                    name="reserved_quantity"
                    hasFeedback
                    validateStatus={jumboValidationStatus}
                    help={jumboHelpMessage}
                  >
                    <Input disabled={mode === "view" || mode === "edit"} />
                  </Form.Item>
                </>
              )}
            </>
          }
          {mode === "edit" && (
            <Form.Item>
              <Button type="primary" htmlType="submit" onClick={onEditFinish}>
                Save Changes
              </Button>
              &nbsp; &nbsp;
              <Button onClick={handleCancelNew}>Cancel</Button>
            </Form.Item>
          )}
        </Form>
      </Modal>
      <Modal
        open={isModalVisibleNewScanner}
        centered
        footer={null}
        maskClosable={false}
        onCancel={handleCancelScanner}
        title="Jumbo Scanner"
        width={700}
      >
        <hr></hr>
        {scannerLoading == "" && (
          <>
            <Row>
              <span
                style={{ color: "red", textAlign: "center", width: "100%" }}
              >
                Please scan the QR in the Jumbo
              </span>
            </Row>
            <Image
              src={QrScanner}
              preview={false}
              height={350}
              width={400}
              alt="Scan the QR Code"
              style={{ marginLeft: "30%" }}
            />
          </>
        )}
        {/* {scannerLoading && (
          <Lottie
            animationData={QRLoading2}
            style={{ width: 200, height: 200 }}
          />
        )} */}
        {scannerLoading == "loading" && (
          <>
            <Image
              src={QRLoading2}
              preview={false}
              height={350}
              width={400}
              alt="Data Loading"
              style={{ marginLeft: "30%" }}
            />
            <Row>
              <span
                style={{ color: "red", textAlign: "center", width: "100%" }}
              >
                Loading....
              </span>
            </Row>
          </>
        )}
        {scannerLoading == "JumboMaster" && (
          <>
            <div
              style={{
                display: "flex",
                height: 370,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Card
                size="default"
                title={
                  <div style={{ textAlign: "center" }}>
                    Verify and Click "Yes" to Add in Store
                  </div>
                }
                style={{ width: "100%" }}
              >
                <div
                  style={{
                    height: "200px",
                    display: "grid",
                    gridTemplateColumns: "50% 50%",
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <Typography.Text style={{ fontWeight: "bold" }}>
                      Jumbo Type :
                    </Typography.Text>
                    <Typography.Text>
                      <Tag color="gold" style={{ fontSize: "14px" }}>
                        {lastScannedValue[9]}
                      </Tag>
                    </Typography.Text>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <Typography.Text style={{ fontWeight: "bold" }}>
                      Jumbo Serial No :
                    </Typography.Text>
                    <Typography.Text>
                      <Tag color="gold" style={{ fontSize: "14px" }}>
                        {lastScannedValue[13]}
                      </Tag>
                    </Typography.Text>
                  </div>

                  <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <Typography.Text style={{ fontWeight: "bold" }}>
                      Width (m) :
                    </Typography.Text>
                    <Typography.Text>
                      <Tag color="gold" style={{ fontSize: "14px" }}>
                        {lastScannedValue[12]}
                      </Tag>
                    </Typography.Text>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <Typography.Text style={{ fontWeight: "bold" }}>
                      Length (m) :
                    </Typography.Text>
                    <Typography.Text>
                      <Tag color="gold" style={{ fontSize: "14px" }}>
                        {lastScannedValue[15]}
                      </Tag>
                    </Typography.Text>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <Typography.Text style={{ fontWeight: "bold" }}>
                      Material :
                    </Typography.Text>
                    <Typography.Text>
                      <Tag color="gold" style={{ fontSize: "14px" }}>
                        {materialName?.materialName}
                      </Tag>
                    </Typography.Text>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <Typography.Text style={{ fontWeight: "bold" }}>
                      Type :
                    </Typography.Text>
                    <Typography.Text>
                      <Tag color="gold" style={{ fontSize: "14px" }}>
                        {materialName?.typeName}
                      </Tag>
                    </Typography.Text>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  <Button
                    type="default"
                    style={{
                      backgroundColor: "green",
                      width: "30%",
                      color: "white",
                    }}
                    onClick={() => handleScannedMaster("Yes")}
                  >
                    Yes
                  </Button>
                  <Button
                    type="default"
                    style={{
                      backgroundColor: "red",
                      width: "30%",
                      color: "white",
                    }}
                    onClick={() => handleScannedMaster("No")}
                  >
                    No
                  </Button>
                </div>
              </Card>
            </div>
          </>
        )}
        {scannerLoading == "JumboMasterMaterialStock" && (
          <>
            <div
              style={{
                display: "flex",
                height: 370,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Card
                size="default"
                title={
                  <div style={{ textAlign: "center" }}>
                    Please add the details below
                  </div>
                }
                style={{ width: "100%" }}
              >
                <Form form={form}>
                  <div
                    style={{
                      height: "200px",
                      display: "grid",
                      gridTemplateColumns: "50% 50%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Typography.Text style={{ fontWeight: "bold" }}>
                        Jumbo Type :
                      </Typography.Text>
                      <Typography.Text>
                        <Tag color="gold" style={{ fontSize: "14px" }}>
                          {lastScannedValue[9]}
                        </Tag>
                      </Typography.Text>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Typography.Text style={{ fontWeight: "bold" }}>
                        Jumbo Serial No :
                      </Typography.Text>
                      <Typography.Text>
                        <Tag color="gold" style={{ fontSize: "14px" }}>
                          {lastScannedValue[13]}
                        </Tag>
                      </Typography.Text>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Typography.Text style={{ fontWeight: "bold" }}>
                        Width (m) :
                      </Typography.Text>
                      <Typography.Text>
                        <Tag color="gold" style={{ fontSize: "14px" }}>
                          {lastScannedValue[12]}
                        </Tag>
                      </Typography.Text>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Typography.Text style={{ fontWeight: "bold" }}>
                        Length (m) :
                      </Typography.Text>
                      <Typography.Text>
                        <Tag color="gold" style={{ fontSize: "14px" }}>
                          {lastScannedValue[15]}
                        </Tag>
                      </Typography.Text>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Typography.Text style={{ fontWeight: "bold" }}>
                        Material :
                      </Typography.Text>
                      <Form.Item
                        name="material_id"
                        id="jumbomaterialtype"
                        className="jumbomaterialtype"
                        rules={[
                          {
                            required: true,
                            message: "Please select Material!",
                          },
                        ]}
                      >
                        <Select
                          allowClear
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          placeholder="Select the Material"
                          // onChange={handleMaterialTypeChange}
                        >
                          {materialOptions.map((material) => (
                            <Select.Option
                              key={material.id}
                              value={material.id}
                            >
                              {material.material_name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Typography.Text style={{ fontWeight: "bold" }}>
                        Type :
                      </Typography.Text>
                      <Form.Item
                        name="type"
                        rules={[
                          {
                            required: true,
                            message: "Please Select the type!",
                          },
                        ]}
                      >
                        <Select
                          // onChange={handleOptionSelect}
                          placeholder="Please select an option"
                        >
                          <Select.Option label="Label" id="1" value="1">
                            Label
                          </Select.Option>
                          <Select.Option label="Ribbon" id="2" value="2">
                            Ribbon
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </div>

                  <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <Button
                      type="default"
                      style={{
                        backgroundColor: "green",
                        width: "30%",
                        color: "white",
                      }}
                      onClick={() => handleJumboMasterMaterialStock("Yes")}
                    >
                      Yes
                    </Button>
                    <Button
                      type="default"
                      style={{
                        backgroundColor: "red",
                        width: "30%",
                        color: "white",
                      }}
                      onClick={() => handleScannedMaster("No")}
                    >
                      No
                    </Button>
                  </div>
                </Form>
              </Card>
            </div>
          </>
        )}
      </Modal>

      <Modal
        centered
        title={
          selectedOption == "1"
            ? `Add Stock - Jumbo`
            : selectedOption == "2"
            ? `Add Stock - Material`
            : selectedOption == "3"
            ? `Add Stock - Label`
            : selectedOption == "4"
            ? `Add Stock - Core`
            : selectedOption == "5"
            ? `Add Stock - Ribbon`
            : "Add Stock"
        }
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        maskClosable={false}
      >
        <Form
          form={form}
          onFinish={onFinish}
          //layout="vertical"
          // initialValues={{ type: selectedListOption }}
        >
          {/* Dropdown to select option */}
          <Form.Item label="Select Option" name="store_type">
            <Select
              onChange={handleOptionSelect}
              placeholder="Please select an option"
            >
              <Select.Option label="Jumbo" id="1" value="1">
                Jumbo
              </Select.Option>
              <Select.Option label="Other Materials" id="2" value="2">
                Other Materials
              </Select.Option>
              <Select.Option label="Label" id="3" value="3">
                Label
              </Select.Option>
              <Select.Option label="Core" id="4" value="4">
                Core
              </Select.Option>
              <Select.Option label="Ribbon" id="5" value="5">
                Ribbon
              </Select.Option>
            </Select>
          </Form.Item>

          {/* Form fields based on selectedOption */}
          {selectedOption === "1" && (
            <>
              <Row gutter={16} align="middle">
                <Col span={22}>
                  <Form.Item
                    label="Jumbo Type"
                    name="type_id"
                    rules={[
                      { required: true, message: "Please select Jumbo!" },
                    ]}
                  >
                    <Select
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder="Select"
                      onChange={handleJumboTypeChange}
                    >
                      {jumboOptions &&
                        jumboOptions.map((jumbo) => (
                          <Select.Option key={jumbo.id} value={jumbo.id}>
                            {jumbo.jumbo_type}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                {/* <Col span={2}>
                  <Popconfirm
                    placement="topLeft"
                    title="Add Jumbo Type in Master"
                    description={
                      <span>
                        <Form.Item name={"JumboType"}>
                          <Input
                            autoComplete="off"
                            onChange={(e) => {
                              setMaterialName({ JumboType: e.target.value });
                            }}
                          />
                        </Form.Item>
                      </span>
                    }
                    onConfirm={(e) => handleJumboTypeMaster(e)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Tooltip title="Add new jumbo">
                      <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={handleAddNewJumbo}
                        style={{ marginBottom: 25, width: 30 }}
                      />
                    </Tooltip>
                  </Popconfirm>
                </Col> */}
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    label={
                      <span>
                        <span
                          title="Jumbo Serial"
                          style={{ color: "#ff4d4f", fontSize: 16 }}
                        >
                          *
                        </span>{" "}
                        Jumbo serial
                      </span>
                    }
                    name="jumbo_serial_no"
                    id="jumboserialno"
                    className="jumboserialno"
                    validateStatus={serialValidationStatus}
                    help={serialHelpMessage}
                    // rules={[{ required: true, message: "Please enter Serial!" }]}
                  >
                    <Input
                      placeholder="Enter Jumbo Serial No"
                      autoComplete="off"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row
                gutter={16}
                align="middle"
                // style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Col span={12}>
                  {materialName == "" || materialName.materialId != "false" ? (
                    <Form.Item label="Material" name="material_id">
                      <Input readOnly />
                    </Form.Item>
                  ) : (
                    <Form.Item
                      label="Material"
                      name="material_id"
                      id="jumbomaterialtype"
                      className="jumbomaterialtype"
                    >
                      <Select
                        allowClear
                        showSearch
                        placeholder="Select the Material"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        validateStatus={materialValidationStatus}
                        help={materialHelpMessage}
                      >
                        {materialOptions &&
                          materialOptions.map((loc) => (
                            <Select.Option key={loc.id} value={loc.id}>
                              {loc.material_name}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  )}
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Width"
                    name="width"
                    id="jumbolength"
                    className="jumbolength"
                    rules={[{ required: true, message: "Please enter width!" }]}
                  >
                    <InputNumber
                      min={1}
                      type="number"
                      changeOnWheel={false}
                      controls={false}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  {materialName == "" || materialName.typeId != "false" ? (
                    <Form.Item label="Type" name="type">
                      <Input readOnly />
                    </Form.Item>
                  ) : (
                    <Form.Item
                      label="Type"
                      name="type"
                      validateStatus={typeValidationStatus}
                      help={typeHelpMessage}
                    >
                      <Select placeholder="Select the type">
                        <Select.Option label="Label" id="1" value="1">
                          Label
                        </Select.Option>
                        <Select.Option label="Ribbon" id="2" value="2">
                          Ribbon
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  )}
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Length"
                    name="length"
                    id="jumbolength"
                    className="jumbolength"
                    rules={[
                      { required: true, message: "Please enter length!" },
                    ]}
                  >
                    <InputNumber
                      min={1}
                      controls={false}
                      type="number"
                      changeOnWheel={false}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {/* <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    label="Quantity"
                    name="quantity"
                    rules={[
                      { required: true, message: "Please enter Quantity!" },
                    ]}
                  >
                    <InputNumber autoComplete="off" min={1} />
                  </Form.Item>
                </Col>
              </Row> */}
            </>
          )}
          {selectedOption === "2" && (
            <>
              <Form.Item
                label="Material Name"
                name="other_material_name"
                rules={[
                  {
                    required: true,
                    message: "Please enter Material!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={
                  <span>
                    <span
                      title="Quantity"
                      style={{ color: "#ff4d4f", fontSize: 16 }}
                    >
                      *
                    </span>{" "}
                    Quantity
                  </span>
                }
                name="quantity"
                hasFeedback
                validateStatus={jumboValidationStatus}
                help={jumboHelpMessage}
              >
                <Input type="number" min={0} autoComplete="off" />
              </Form.Item>
            </>
          )}
          {selectedOption === "3" && (
            <>
              <Form.Item
                label="Label Size"
                name="type_id"
                rules={[
                  { required: true, message: "Please select Label Size!" },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    const optionText = option.children.join(" ").toLowerCase();
                    return optionText.includes(input.toLowerCase());
                  }}
                  placeholder="Select"
                  // onChange={handleLabelTypeChange}
                >
                  {labelOptions.map((label) => (
                    <Select.Option key={label.id} value={label.id}>
                      {label.label_width} x {label.label_length}{" "}
                      {label.label_measure}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {/* <Form.Item label="Unit Per Size" name="ups">
                <Input readOnly />
              </Form.Item> */}
              <Form.Item
                label={
                  <span>
                    <span
                      title="Quantity"
                      style={{ color: "#ff4d4f", fontSize: 16 }}
                    >
                      *
                    </span>{" "}
                    Quantity
                  </span>
                }
                name="quantity"
                hasFeedback
                validateStatus={jumboValidationStatus}
                help={jumboHelpMessage}
              >
                <Input autoComplete="off" type="number" min={0} />
              </Form.Item>
            </>
          )}
          {selectedOption === "4" && (
            <>
              {/* Add Core specific fields here */}
              <Form.Item
                label="Core Specific Field"
                name="type_id"
                rules={[
                  {
                    required: true,
                    message: "Please enter Core specific field!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Select"
                  onChange={handleCoreTypeChange}
                >
                  {coreOptions.map((core) => (
                    <Select.Option key={core.id} value={core.id}>
                      {core.core_type +
                        " - " +
                        core.core_dia +
                        " " +
                        core.core_dia_measure +
                        " x " +
                        core.core_length +
                        " " +
                        core.core_length_measure}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {/* <Form.Item label="Core Type" name="core_type">
                <Input readOnly />
              </Form.Item> */}
              <Form.Item
                label={
                  <span>
                    <span
                      title="Quantity"
                      style={{ color: "#ff4d4f", fontSize: 16 }}
                    >
                      *
                    </span>{" "}
                    Quantity
                  </span>
                }
                name="quantity"
                hasFeedback
                validateStatus={jumboValidationStatus}
                help={jumboHelpMessage}
              >
                <Input autoComplete="off" type="number" min={0} />
              </Form.Item>
            </>
          )}
          {selectedOption === "5" && (
            <>
              <Form.Item
                label="Ribbon Size"
                name="type_id"
                rules={[
                  { required: true, message: "Please select Ribbon Size!" },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    const optionText = option.children.join(" ").toLowerCase();
                    return optionText.includes(input.toLowerCase());
                  }}
                  placeholder="Select"
                >
                  {ribbonOptions.map((label) => (
                    <Select.Option key={label.id} value={label.id}>
                      {label.ribbon_width} x {label.ribbon_length}{" "}
                      {label.ribbon_measure}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={
                  <span>
                    <span
                      title="Quantity"
                      style={{ color: "#ff4d4f", fontSize: 16 }}
                    >
                      *
                    </span>{" "}
                    Quantity
                  </span>
                }
                name="quantity"
                hasFeedback
                validateStatus={jumboValidationStatus}
                help={jumboHelpMessage}
              >
                <Input autoComplete="off" type="number" min={0} />
              </Form.Item>
            </>
          )}

          {selectedOption ? (
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Add
              </Button>
              <Button onClick={handleCancel} style={{ marginLeft: "8px" }}>
                Cancel
              </Button>
            </Form.Item>
          ) : (
            <p>Please select an option to continue.</p>
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default Store;
