import React from 'react';
import './App.css';
import SignIn from "./Components/SignIn/SignIn";
import LayOut from "./LayOut";


function App() {
  const isLoggedIn = localStorage.getItem("loginHarnet") == "true";
  console.log("Login", isLoggedIn,localStorage.getItem("login") );
  return (
    <div className="App">
      {/* <LayOut></LayOut> */}
      {isLoggedIn ? <LayOut /> : <SignIn />}
    </div>

  );

}

export default App;
