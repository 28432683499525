const formatDate = (date) => {
  if (!date) return ""; // Return empty string if date is null or undefined

  // Ensure date is of type Date
  if (!(date instanceof Date)) {
    // If date is not a Date object, try to parse it
    date = new Date(date);
    // If parsing fails, return empty string
    if (isNaN(date.getTime())) return "";
  }

  // Format the date as DD-MM-YYYY
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
};
export default formatDate;
