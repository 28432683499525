import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Card,
  Select,
  message,
  Row,
  Col,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  CreateRecord,
  ReadRecord,
  UpdateRecord,
} from "../../../Service/API/ApiService";
import debounce from "lodash/debounce";

const CustomerMaster = () => {
  const [users, setUsers] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editUserData, setEditUserData] = useState(null);
  const [ViewUserData, setViewUserData] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("Active");
  const [searchQuery, setSearchQuery] = useState("");
  const [emailValidationStatus, setemailValidationStatus] = useState("");
  const [eHelpMessage, setEmailHelpMessage] = useState("");
  const [mobileValidationStatus, setMobileValidationStatus] = useState("");
  const [mobileHelpMessage, setMobileHelpMessage] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  useEffect(() => {
    apiCheck(pagination.current, pagination.pageSize, searchQuery);
    localStorage.setItem("Title", "Core");
    //userListData();
    setSelectedStatus("Active");
  }, [searchQuery]);

  async function apiCheck(page, pageSize, search = "") {
    let searchFilter = "";
    let offset = (page - 1) * pageSize;
    if (search != "") {
      if (search.toLowerCase().trim() === "active") {
        searchFilter = `AND status = 1`;
      } else if (search.toLowerCase().trim() === "inactive") {
        searchFilter = `AND status = 0`;
      } else {
        searchFilter = ` AND (customer_name like '%${search}%' OR email like '%${search}%'
       OR location_name like '%${search}%' OR id like '%${search}%'
       OR contact_person_name like '%${search}%'
        OR contact_number like '%${search}%'
        OR designation like '%${search}%'
        OR mode_of_delivery like '%${search}%'
       )  `;
      }
      offset = 0;
    }

    let searchData = `SELECT * from customer_master where ActiveStatus=1 ${searchFilter}  ORDER BY status DESC, id DESC  limit ${pageSize} offset ${offset}`;
    let searchDataCount = `SELECT COUNT(*) as TotalCount
    FROM customer_master 
    WHERE ActiveStatus = 1 ${searchFilter}`;
    const apiRequest1 = ReadRecord(searchData).then((res) => {
      console.log("apiRequest1", res);
      return res;
    });
    const apiRequest2 = ReadRecord(searchDataCount).then((res) => {
      console.log("apiRequest2", res);
      return res;
    });
    try {
      //   Execute both requests concurrently
      const [data1, data2] = await Promise.all([apiRequest1, apiRequest2]);

      //   Handle the responses
      // setOtherData(data1); // Update with the data from Endpoint1
      setUsers(data1.data[0].rows); // Update with the data from Endpoint2
      let total = data2.data[0].rows[0].TotalCount; // Update with the data from Endpoint2
      // Update pagination state
      setPagination((prevPagination) => ({
        ...prevPagination,
        current: page,
        pageSize: pageSize,
        total: total, // Update total from your API response
      }));

      // Reset pagination to the first page
      //tableRef.current.onChange(1);
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
    }
  }

  const columns = [
    {
      title: "S.No.",
      dataIndex: "serial",
      key: "serial",
      align: "center",
      width: 10,
      render: (text, record, index) =>
        index + (pagination.current - 1) * pagination.pageSize + 1,
    },
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      key: "Customer Name",
      align: "center",
      width: 100,
    },
    {
      title: "Contact Person Name",
      dataIndex: "contact_person_name",
      key: "Contact Person Name",
      align: "center",
      width: 100,
    },
    {
      title: "Contact Number",
      dataIndex: "contact_number",
      key: "Contact Number",
      align: "center",
      width: 30,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "em",
      align: "center",
      width: 50,
    },
    {
      title: "Designation",
      dataIndex: "designation",
      key: "degn",
      align: "center",
      width: 30,
    },
    {
      title: "Mode of Delivery",
      dataIndex: "mode_of_delivery",
      key: "Mode of Delivery",
      align: "center",
      width: 30,
    },
    {
      title: "Location",
      dataIndex: "location_name",
      key: "Location",
      align: "center",
      width: 30,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 30,
      render: (status) => <span>{status == 1 ? "Active" : "Inactive"}</span>,
    },
    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      align: "center",
      width: 200,
      render: (text, record) => (
        <>
          <Button
            icon={<EyeOutlined />}
            style={{ backgroundColor: "#be991d" }}
            type="primary"
            title="View"
            onClick={() => handleView(record)}
          ></Button>
          &nbsp;
          <Button
            icon={<EditOutlined />}
            style={{ backgroundColor: "#005c9f" }}
            type="primary"
            title="Edit"
            onClick={() => handleEdit(record)}
          ></Button>
          &nbsp;
          <Button
            icon={<DeleteOutlined />}
            type="primary"
            style={{ backgroundColor: "#d7524b" }}
            title="Delete"
            onClick={() => handleDelete(record)}
          ></Button>
        </>
      ),
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
    setEditUserData(null);
    setViewUserData(null);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    setEditUserData("");
    setViewUserData("");
    setMobileValidationStatus("");
    setMobileHelpMessage("");
    setEmailHelpMessage("");
    setemailValidationStatus("");
  };

  const onFinish = (values) => {
    try {
      if (values.email == undefined || values.email == "") {
        setemailValidationStatus("error");
        eHelpMessage("Please enter email");
        return;
      }
      if (values.contact_number == undefined || values.contact_number == "") {
        setMobileValidationStatus("error");
        mobileHelpMessage("Please enter contact number");
        return;
      }
      if (
        emailValidationStatus == "error" ||
        mobileValidationStatus == "error"
      ) {
        return;
      }

      handleSubmit(values);
    } catch (error) {
      console.error("Error:", error);
      message.error("Failed to submit the form. Please try again later.");
    }
  };

  const handleSubmit = async (values) => {
    try {
      const data = {
        customer_name: values.customer_name,
        email: values.email,
        contact_person_name: values.contact_person_name,
        designation: values.designation,
        contact_number: values.contact_number,
        mode_of_delivery: values.mode_of_delivery,
        location_name: values.location_name,
        status: values.status === "Active" ? 1 : 0,
      };

      if (editUserData) {
        const updatedData = { ...editUserData, ...values };
        updatedData.status = values.status === "Active" ? 1 : 0;

        await UpdateRecord("customerMaster", [updatedData]);
        message.success("Customer updated successfully");
      } else {
        await CreateRecord("customerMaster", [data]);
        message.success("Customer created successfully");
      }

      apiCheck(pagination.current, pagination.pageSize, searchQuery);
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      console.error("Error:", error);
      message.error("Failed to submit the form. Please try again later.");
    }
  };

  const handleView = (record) => {
    const recordWithStatusLabel = { ...record };

    recordWithStatusLabel.status =
      recordWithStatusLabel.status == 1 ? "Active" : "Inactive";

    setViewUserData(recordWithStatusLabel);
    setIsModalVisible(true);
    form.setFieldsValue(recordWithStatusLabel);
  };

  const handleEdit = (record) => {
    const recordWithStatusLabel = { ...record };

    recordWithStatusLabel.status =
      recordWithStatusLabel.status == 1 ? "Active" : "Inactive";
    console.log("record", recordWithStatusLabel);
    setEditUserData(recordWithStatusLabel);
    setIsModalVisible(true);
    form.setFieldsValue(recordWithStatusLabel);
  };

  const handleDelete = (record) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this record?"
    );
    if (isConfirmed) {
      console.log("Before deleting record", record.id);
      let data = {
        id: record.id,
        ActiveStatus: false,
      };
      UpdateRecord("deleteCustomerMaster", [data]).then((res) => {
        apiCheck(pagination.current, pagination.pageSize, searchQuery);
        console.log("User record deleted", res);
        message.info("Customer has been deleted");
      });
    }
  };

  const handleSearch = (value) => {
    setSearchQuery(value);
  };

  const debounceCheckUniqueEmail = debounce(async (email) => {
    if (!editUserData) {
      const isEmailAvailable = await checkUniqueField("email", email);
      setemailValidationStatus(isEmailAvailable ? "error" : "success");
      setEmailHelpMessage(isEmailAvailable ? "Email already exists" : "");
    } else {
      const isEmailAvailable = await checkEdit("email", email);
      setemailValidationStatus(isEmailAvailable ? "error" : "success");
      setEmailHelpMessage(isEmailAvailable ? "email already exists" : "");
    }
  }, 500);

  const debounceCheckUniquePhone = debounce(async (phone) => {
    if (!editUserData) {
      const isPhoneAvailable = await checkUniqueField("contact_number", phone);
      setMobileValidationStatus(isPhoneAvailable ? "error" : "success");
      setMobileHelpMessage(
        isPhoneAvailable ? "Phone number already exists" : ""
      );
    } else {
      const isPhoneAvailable = await checkEdit("contact_number", phone);
      setMobileValidationStatus(isPhoneAvailable ? "error" : "success");
      setMobileHelpMessage(
        isPhoneAvailable ? "Phone number already exists" : ""
      );
    }
  }, 500);
  const checkEdit = async (field, value) => {
    try {
      if (value.trim() == "") {
        return false;
      }
      const res =
        await ReadRecord(`select count(*) as count from customer_master where ${field} = '${value}' AND 
              (status=1 or status=0) AND ActiveStatus=1 AND id!='${editUserData.id}'`);
      return res.data[0]?.rows[0].count > 0;
    } catch (error) {
      console.error(`Error checking uniqueness for ${field}:`, error);
      return false;
    }
  };
  const checkUniqueField = async (field, value) => {
    if (value.trim() == "") {
      return false; // Return false if value is empty
    }
    try {
      const res = await ReadRecord(`
        SELECT COUNT(*) AS count FROM customer_master
        WHERE ${field} = '${value}'
        AND (status = 1 or status=0) AND ActiveStatus=1
      `);
      return res.data[0]?.rows[0].count > 0;
    } catch (error) {
      console.error(`Error checking uniqueness for ${field}:`, error);
      return false;
    }
  };

  const handlePhoneChange = (e) => {
    const phone = e.target.value;
    if (!phone.trim() || phone.trim() == undefined) {
      setMobileValidationStatus("error");
      setMobileHelpMessage("Phone number cannot be empty.");
      return;
    }

    // Check if the phone number matches the pattern
    const isPhoneValid = /^[6-9][0-9]{9}$/.test(phone);
    if (!isPhoneValid) {
      setMobileValidationStatus("error");
      setMobileHelpMessage("Please enter a valid phone number");
      return;
    }

    setMobileValidationStatus("success");
    setMobileHelpMessage("");
    // Call debounce function to check phone number availability
    debounceCheckUniquePhone(phone);
  };
  const handleEmailChange = (e) => {
    const email = e.target.value;
    if (!email.trim() || email.trim() == undefined) {
      setemailValidationStatus("error");
      setEmailHelpMessage("Phone number cannot be empty.");
      return;
    }

    // Check if the phone number matches the pattern
    const isEmailValid =
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
    if (!isEmailValid) {
      setemailValidationStatus("error");
      setEmailHelpMessage("Please enter a valid Email id");
      return;
    }
    setemailValidationStatus("success");
    setEmailHelpMessage("");
    // Call debounce function to check phone number availability
    debounceCheckUniqueEmail(email);
  };

  return (
    <div>
      <>
        <div style={{ display: "flex", alignItems: "center" }}>
          <p
            style={{
              color: "#333",
              marginRight: "auto",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            Customer Master
          </p>
          <Input
            placeholder="Search"
            allowClear
            style={{ width: 350, margin: "0 16px" }}
            prefix={<SearchOutlined />}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <Button type="primary" onClick={showModal} icon={<PlusOutlined />}>
            Add New Customer
          </Button>
        </div>
        {/* <Table dataSource={filteredUsers} scroll={{y:400}} columns={columns} rowClassName={(record, index) => */}
        <Table
          size="small"
          bordered
          dataSource={users}
          columns={columns}
          rowClassName={(record, index) =>
            index % 2 === 1 ? "even-row" : "odd-row"
          }
          pagination={{
            pageSize: pagination.pageSize,
            onChange: (page, pageSize) => {
              apiCheck(page, pageSize);
            },
            total: pagination.total,
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
      </>

      <Modal
        title={
          editUserData
            ? "Edit Customer"
            : ViewUserData
            ? "View Customer"
            : "Create Customer"
        }
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={800}
        maskClosable={false}
      >
        <hr></hr>
        &nbsp;
        <Form
          form={form}
          name="createUserForm"
          id="customerform"
          className="customerform"
          onFinish={onFinish}
          layout="horizontal" // Changed layout to vertical for better control
          initialValues={{ status: "Active" }}
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 16 }}
          autoComplete="off"
          disabled={ViewUserData}
        >
          <Row gutter={16}>
            {" "}
            {/* Added Row to create columns */}
            <Col span={12}>
              <Form.Item
                label="Customer Name"
                name="customer_name"
                id="customername"
                className="customername"
                rules={[
                  { required: true, message: "Please enter customer name!" },
                  {
                    pattern:
                      /^(?=.*[a-zA-Z])[a-zA-Z0-9\s!@#$%^&*()_+=[\]{};:'"\\|,.<>/?]{2,}$/,
                    message: "Please enter valid customer name",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Contact Person Name"
                name="contact_person_name"
                id="customerperson"
                className="customerperson"
                rules={[
                  {
                    required: true,
                    message: "Please enter contact person name!",
                  },
                  {
                    pattern:
                      /^(?=.*[a-zA-Z])[a-zA-Z0-9\s!@#$%^&*()_+=[\]{};:'"\\|,.<>/?]{2,}$/,
                    message: "Please enter valid contact person name !",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={
                  <span>
                    <span
                      title="Email"
                      style={{ color: "#ff4d4f", fontSize: 16 }}
                    >
                      *
                    </span>{" "}
                    Email
                  </span>
                }
                name="email"
                id="customeremail"
                className="customeremail"
                type="email"
                hasFeedback
                validateStatus={emailValidationStatus}
                help={eHelpMessage}
              >
                <Input onChange={handleEmailChange} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                // label={
                //   <span>
                //     <span
                //       title="Phonenumber"
                //       style={{ color: "#ff4d4f", fontSize: 16 }}
                //     >
                //       *
                //     </span>{" "}
                //     Mobile No
                //   </span>
                // }
                label="Mobile No"
                name="contact_number"
                id="customercontactnumber"
                className="customercontactnumber"
                hasFeedback
                // validateStatus={mobileValidationStatus}
                // help={mobileHelpMessage}
                rules={[
                  { required: true, message: "Please enter Mobile Number!" },
                ]}
              >
                <Input /*onChange={handlePhoneChange}*/ type="number" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Designation"
                name="designation"
                id="customerdesignation"
                className="customerdesignation"
                rules={[
                  {
                    pattern: /^[a-zA-Z. ]+$/,
                    message: "Designation should only contain characters!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Mode of Delivery"
                name="mode_of_delivery"
                id="delivery"
                className="delivery"
                rules={[
                  { required: true, message: "Please enter mode of delivery!" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Location"
                name="location_name"
                id="customerlocation"
                className="customerlocation"
                rules={[{ required: true, message: "Please enter location !" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Status"
                name="status"
                id="status"
                className="status"
                rules={[{ required: true, message: "Please select status!" }]}
              >
                <Select
                  onChange={(value) => setSelectedStatus(value)}
                  placeholder="Select"
                >
                  <Select.Option value="Active">Active</Select.Option>
                  <Select.Option value="Inactive">Inactive</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item wrapperCol={{ offset: 10, span: 16 }}>
            {ViewUserData ? null : (
              <>
                <Button
                  type="primary"
                  htmlType="submit"
                  id="customerformsubmit"
                  className="customerformsubmit"
                >
                  {editUserData ? "Update" : "Add"}
                </Button>
                <Button style={{ marginLeft: 8 }} onClick={handleCancel}>
                  Cancel
                </Button>
              </>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default CustomerMaster;
