import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Select,
  Tooltip,
  DatePicker,
  InputNumber,
  Row,
  Col,
  message,
  Tag,
  Typography,
  Radio,
  Spin,
  Checkbox,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  EyeOutlined,
  CloseOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  CreateRecord,
  ReadRecord,
  UpdateRecord,
} from "../../Service/API/ApiService";
import formatDate from "../../Components/formatDate";

const Production = () => {
  const [orders, setOrderDetails] = useState([]);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [editOrders, setEditOrders] = useState(null);
  const [viewOrders, setViewOrders] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
  const [selectedJumboType, setSelectedJumboType] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [form] = Form.useForm();
  const [jumboSerials, setJumboSerials] = useState(null);
  const [JumboOptions, setJumbodetails] = useState(null);
  const [jumboStock, setJumboStock] = useState([]);
  const [Corequantity, setcorequantity] = useState([]);
  const [MaterialOptions, setMaterialDetails] = useState(null);
  const [labelStockNeeded, setLabelStockNeeded] = useState("");
  const loggedinuser = localStorage.getItem("usernameHarnet");
  const loggedinUserId = localStorage.getItem("userID");
  const harnetRoleAccess = JSON.parse(localStorage.getItem("harnetRoleAccess")) || [];
  const userRoleHarnet = localStorage.getItem("userRoleHarnet") || [];
  const [orderDate, setOrderDate] = useState(new Date());
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [LabelOptions, setLabeldetails] = useState(null);
  const [ribbonOptions, setRibbonOptions] = useState([]);
  const { RangePicker } = DatePicker;
  const [typeFilter, settypeFilter] = useState("1");
  const [searchQuery, setSearchQuery] = useState("");
  const [productionFilter, setProductionFilter] = useState("");
  const [coreValidationStatus, setCoreValidationStatus] = useState("");
  const [coreHelpMessage, setCoreHelpMessage] = useState("");
  const [selectedCoreId, setSelectedCoreId] = useState(null);
  const [OrderStatusQuery, setOrderStatusQuery] = useState("");
  const [OrderStatusQueryType, setOrderStatusQueryType] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  useEffect(() => {
    apiCheck(
      pagination.current,
      pagination.pageSize,
      searchQuery,
      typeFilter,
      formatDate(fromDate),
      formatDate(toDate),
      OrderStatusQuery,
      OrderStatusQueryType
    );
  }, [searchQuery]);
  useEffect(() => {
    // getJumboDetails();
    getLabelDetails();
    fetchRibbonOptions();
    getCoreDetails();
    getMaterialDetail();
  }, []);
  useEffect(() => {
    console.log("selectedRows", selectedRows);
  }, [selectedRows]);
  function getCoreDetails() {
    ReadRecord(
      `SELECT id,core_type,core_size,core_dia,core_dia_measure,core_length,core_length_measure FROM core_master WHERE status = 1 and ActiveStatus = 1 order by id desc`
    ).then((res) => {
      setSelectedCoreId(res.data[0]?.rows);
    });
  }
  const fetchRibbonOptions = async () => {
    try {
      const response = await ReadRecord(
        `SELECT id,ribbon_length,ribbon_width,ribbon_measure FROM ribbon_master WHERE status = 1 and ActiveStatus = 1`
      );
      setRibbonOptions(response.data[0]?.rows || []);
    } catch (error) {
      console.error("Error fetching Label options:", error);
    }
  };
  useEffect(() => {
    console.log("jumboStock", jumboStock);
  }, [jumboStock]);
  function getMaterialDetail() {
    ReadRecord(
      `SELECT id,material_name FROM material_master WHERE status = 1 and ActiveStatus = 1`
    ).then((res) => {
      setMaterialDetails(res.data[0]?.rows);
    });
  }
  function getLabelDetails() {
    ReadRecord(
      `SELECT id,label_length,label_width,label_measure FROM label_master WHERE status = 1 and ActiveStatus = 1`
    ).then((res) => {
      setLabeldetails(res.data[0]?.rows);
    });
  }
  async function apiCheck(
    page,
    pageSize,
    search = "",
    typeFilter1 = typeFilter,
    fromDate = null,
    toDate = null,
    query = "",
    queryType = ""
  ) {
    let dateFilter = "";
    if (fromDate && toDate) {
      dateFilter = `AND production_date BETWEEN '${formatDate(
        fromDate
      )}' AND '${formatDate(toDate)}'`;
    }
    let searchtypeFilter = "";
    let searchOrderFilter = "";
    let joinFilter = "";
    let orderName = "";
    if (typeFilter1 != "") {
      if (typeFilter1 == "1") {
        searchtypeFilter = `AND t1.production_type='1'`;
        searchOrderFilter = ` or t4.order_number like '%${search.trim()}%'`;
        joinFilter = `JOIN
        orders AS t4 ON t1.order_id = t4.id`;
        orderName = `t4.order_number,`;
      } else {
        searchtypeFilter = `AND t1.production_type='2'`;
      }
    }
    let searchFilter = "";
    let offset = (page - 1) * pageSize;
    if (search != "") {
      searchFilter = `AND (
               t5.material_name like '%${search.trim()}%'
                or t3.username like '%${search.trim()}%'
                or t2.jumbo_type like '%${search.trim()}%'
                or t1.pro_quantity like '%${search.trim()}%'
                or t1.order_based_quantity like '%${search.trim()}%'
                or t1.production_status like '%${search.trim()}%'
                or t1.production_number like '%${search.trim()}%' ${searchOrderFilter})`;
      offset = 0;
    }

    let searchData = `SELECT 
        t1.*, 
        CAST(ROUND(t1.pro_quantity, 2) AS CHAR) as pro_quantity,
        CAST(ROUND(t1.order_based_quantity, 2) AS CHAR) as order_based_quantity,
        t2.jumbo_type,
        t2.type,
        t3.username,
        ${orderName}
        t5.material_name
    FROM 
        production AS t1 
    JOIN 
        jumbo_master AS t2 ON t1.jumbo_id = t2.id 
    JOIN 
        user_master AS t3 ON t1.user_id = t3.id
    ${joinFilter}
    JOIN
        material_master AS t5 ON t2.material_id = t5.id
    WHERE 
        t1.status = 1 
        AND t1.ActiveStatus = 1 ${searchFilter} ${query} ${queryType} ${dateFilter} ${searchtypeFilter} ORDER BY t1.id DESC limit ${pageSize} offset ${offset}`;

    let searchDataCount = `SELECT COUNT(*) as TotalCount
       FROM 
        production AS t1 
   JOIN 
        jumbo_master AS t2 ON t1.jumbo_id = t2.id
   JOIN 
        user_master AS t3 ON t1.user_id = t3.id
        ${joinFilter}
   JOIN 
        material_master AS t5 ON t2.material_id = t5.id
    WHERE 
        t1.status = 1         
        AND t1.ActiveStatus = 1 ${searchFilter} ${query} ${queryType} ${dateFilter} ${searchtypeFilter}`;
    const apiRequest1 = ReadRecord(searchData).then((res) => {
      console.log("apiRequest1", res);
      return res;
    });
    const apiRequest2 = ReadRecord(searchDataCount).then((res) => {
      console.log("apiRequest2", res);
      return res;
    });
    try {
      //   Execute both requests concurrently
      const [data1, data2] = await Promise.all([apiRequest1, apiRequest2]);

      //   Handle the responses
      // setOtherData(data1); // Update with the data from Endpoint1
      setOrderDetails(data1.data[0].rows); // Update with the data from Endpoint2
      let total = data2.data[0].rows[0].TotalCount; // Update with the data from Endpoint2
      // Update pagination state
      setPagination((prevPagination) => ({
        ...prevPagination,
        current: page,
        pageSize: pageSize,
        total: total, // Update total from your API response
      }));

      // Reset pagination to the first page
      //tableRef.current.onChange(1);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  function getJumboDetails(value) {
    ReadRecord(
      `SELECT id,jumbo_type FROM jumbo_master WHERE status = 1 and ActiveStatus = 1 and type = '${value}' order by id desc;`
    ).then((res) => {
      setJumbodetails(res.data[0]?.rows || []);
    });
  }
  const columns = [
    {
      title: "S.No.",
      dataIndex: "serial",
      key: "serial",
      // width: 20,
      align: "center",
      render: (text, record, index) =>
        index + (pagination.current - 1) * pagination.pageSize + 1,
    },
    {
      title: "Pro ID",
      dataIndex: "production_number",
      key: "order_number2",
      filterMode: "tree",
      filterSearch: true,
      // width: 50,
      align: "center",
    },
    ...(typeFilter === "1"
      ? [
          {
            title: "Order ID",
            dataIndex: "order_number",
            key: "order_number",
            // width: 50,
            align: "center",
          },
        ]
      : []),
    {
      title: "Production Date",
      dataIndex: "CreatedDate",
      key: "CreatedDate",
      // width: 90,
      align: "center",
    },
    {
      title: "Jumbo Type",
      dataIndex: "jumbo_type",
      key: "username2",
      // width: 50,
      align: "center",
    },
    {
      title: "Material",
      dataIndex: "material_name",
      key: "material_name",
      // width: 50,
      align: "center",
      filters: MaterialOptions?.map((item) => ({
        text: item.material_name,
        value: item.material_name,
      })),
      filteredValue: filteredInfo.material_name || null,
      onFilter: (value, record) => record.material_name === value,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "typee",
      // width: 50,
      align: "center",
      filters: [
        { text: "Label", value: "1" },
        { text: "Ribbon", value: "2" },
      ],
      filteredValue: filteredInfo.typee || null,
      onFilter: (value, record) => record.type === value,
      render: (index, record) => (record.type == "1" ? "Label" : "Ribbon"),
    },
    {
      title: "Size",
      dataIndex: "label_id",
      key: "mtype1",
      width: 80,
      align: "center",
      render: (index, record) => {
        if (record.type == "1") {
          const selected = LabelOptions?.find(
            (option) => option.id === record.label_id
          );
          return `${selected?.label_width} x ${selected?.label_length}`;
        } else {
          const selected = ribbonOptions?.find(
            (option) => option.id === record.label_id
          );
          return `${selected?.ribbon_width} x ${selected?.ribbon_length}`;
        }
      },
    },
    ...(typeFilter === "1"
      ? [
          {
            title: "Order Quantity",
            dataIndex: "order_based_quantity",
            key: "order_based_quantity",
            // width: 50,
            align: "center",
          },
        ]
      : []),
    {
      title: "Production Quantity",
      dataIndex: "pro_quantity",
      key: "pro_quantity",
      // width: 50,
      align: "center",
    },
    {
      title: "Production Status",
      dataIndex: "production_status",
      key: "ordsts",
      // width: 50,
      align: "center",
      filters: [
        { text: "Awaiting Approval", value: "Awaiting Approval" },
        { text: "In Process", value: "In Process" },
        { text: "Completed", value: "Completed" },
        { text: "Rejected", value: "Rejected" },
      ],
      filteredValue: filteredInfo.ordsts || null,
      onFilter: (value, record) => record.production_status === value,
      render: (production_status) => {
        let color;
        let text;
        switch (production_status) {
          case "In Process":
            color = "gold";
            break;
          case "Awaiting Approval":
            color = "blue";
            break;
          case "Hold":
            color = "orange";
            break;
          case "Rejected":
            color = "brown";
            break;
          case "Completed":
            color = "green";
            break;
          default:
            color = "blue";
            break;
        }
        return <Tag color={color}>{production_status}</Tag>;
      },
    },
    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      width: 130,
      align: "center",
      fixed: "right",
      render: (text, record) => (
        <>
          {(harnetRoleAccess && harnetRoleAccess.includes("20")) ||
          (userRoleHarnet && userRoleHarnet === "1") ? (
            <Button
              icon={<EyeOutlined />}
              type="primary"
              style={{ backgroundColor: "#be991d" }}
              title="View"
              onClick={() => handleView(record)}
            ></Button>
          ) : (
            ""
          )}
          &nbsp;
          {(harnetRoleAccess && harnetRoleAccess.includes("21")) ||
          (userRoleHarnet && userRoleHarnet === "1") ? (
            <Button
              icon={<EditOutlined />}
              style={{ backgroundColor: "#005c9f" }}
              type="primary"
              title="Edit"
              onClick={() => handleEdit(record)}
              disabled={
                record.production_status != "Awaiting Approval" ||
                typeFilter == "1"
              }
            ></Button>
          ) : (
            ""
          )}
          &nbsp;
          {(harnetRoleAccess && harnetRoleAccess.includes("22")) ||
          (userRoleHarnet && userRoleHarnet === "1") ? (
            <Button
              icon={<CloseOutlined />}
              type="primary"
              style={{ backgroundColor: "#d7524b" }}
              title="Cancel"
              onClick={() => handleDelete(record)}
              disabled={
                record.production_status != "Awaiting Approval" ||
                typeFilter == "1"
              }
            ></Button>
          ) : (
            ""
          )}
        </>
      ),
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
    setEditOrders(null);
    setViewOrders(null);
  };

  const handleCancel = () => {
    setIsRejectModalVisible(false);
    setTimeout(() => {
      setIsModalVisible(false);
      form.resetFields();
      setProductionFilter("");
      setEditOrders("");
      setViewOrders("");
      setJumboStock([]);
      setLabelStockNeeded("");
      setSelectedRows([]);
    }, 10);
    //setIsModalVisible(false);
  };
  const handleNewCancel = () => {
    setIsRejectModalVisible(false);
  };

  const onFinish = (values) => {
    //   console.log("selected", selected);
    let label_quantity_sqm = 0;
    if (productionFilter == "1") {
      const selected = LabelOptions.find(
        (option) => option.id === values.label_size
      );
      console.log("Label");
      label_quantity_sqm = Math.round(
        ((((Number(selected.label_width) + 5) *
          (Number(selected.label_length) + 3)) /
          645.16) *
          Number(values.production_quantity)) /
          1550
      );
    } else {
      const selected = ribbonOptions.find(
        (option) => option.id === values.label_size
      );
      console.log("Ribbon");
      label_quantity_sqm = Math.round(
        ((selected.ribbon_width * selected.ribbon_length) / 1000) *
          values.production_quantity
      );
    }
    try {
      const data = [
        {
          jumbo_id: values.jumbo_id,
          production_label_quantity_sqm: label_quantity_sqm,
          label_id: values.label_size,
          production_date: values.production_date,
          production_label_quantity: values.production_quantity,
          pro_quantity: values.production_quantity,
          production_status: "Awaiting Approval",
          user_id: loggedinUserId,
          // production_label_extra_needed_sqm: labelStockNeeded,
          production_type: 2,
          // production_child: jumboStock,
        },
      ];
      if (editOrders) {
        console.log("editOrders", editOrders);
        const editData = [
          {
            id: editOrders.id,
            production_label_quantity: values.production_quantity,
            pro_quantity: values.production_quantity,
            production_label_quantity_sqm: label_quantity_sqm,
          },
        ];
        UpdateRecord("production", editData).then((res) => {
          message.success("Production Updated");
          form.resetFields();
          setJumboStock([]);
          setLabelStockNeeded("");
          handleCancel();
          // apiCheck(
          //   pagination.current,
          //   pagination.pageSize,
          //   searchQuery,
          //   OrderStatusQuery,
          //   OrderStatusQueryType
          // );
          apiRefresh();
        });
      } else {
        CreateRecord("production", data).then((response) => {
          // console.log("response", response);
          message.success("Production Request Sent");
          form.resetFields();
          setJumboStock([]);
          setLabelStockNeeded("");
          handleCancel();
          // apiCheck(
          //   pagination.current,
          //   pagination.pageSize,
          //   searchQuery,
          //   OrderStatusQuery,
          //   OrderStatusQueryType
          // );
          apiRefresh();
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleProductionStatus = async (value) => {
    if (value === "In Process") {
      const core_size = form.getFieldValue("core_size");
      const core_quantity = form.getFieldValue("core_quantity");

      if (
        core_size === "" ||
        core_size === undefined ||
        core_size === null ||
        core_quantity === "" ||
        core_quantity === undefined ||
        core_quantity === null
      ) {
        form.validateFields();
        setCoreValidationStatus("error");
        setCoreHelpMessage("Enter Quantity!");
        return;
      } else if (core_quantity > Corequantity?.quantity) {
        setCoreValidationStatus("error");
        setCoreHelpMessage(`Available - ${Corequantity?.quantity}`);
        return;
      }

      if (selectedRows.length === 0) {
        message.error("Please Choose jumbo!");
        return;
      } else {
        let totalAvailable = 0;
        selectedRows.forEach((item) => {
          totalAvailable += parseFloat(item.available_quantity);
        });

        if (totalAvailable >= form.getFieldValue("label_quantity_sqm")) {
          // const labelStockNeeded = form.getFieldValue("label_quantity_sqm");

          if (labelStockNeeded < 0) {
            message.error("Not enough Stock to Process this Production!");
          } else {
            const data = [
              {
                id: viewOrders.id,
                production_status: "In Process",
                production_label_extra_needed_sqm: labelStockNeeded,
                core_quantity: Number(core_quantity),
                core_size: Number(core_size),
              },
            ];

            const coreData = [
              {
                id: Corequantity?.coreId,
                reserved_quantity:
                  Number(Corequantity?.coreReservedQuantity) +
                  Number(core_quantity),
              },
            ];

            try {
              const response = await UpdateRecord("production", data);
              if (response.feedback[0].count > 0) {
                await CreateRecord("production_child", selectedRows);
                await UpdateRecord("store", coreData);

                await Promise.all(
                  selectedRows.map(async (item) => {
                    if (item.jumbo_serial_id !== undefined) {
                      await UpdateRecord("store", [
                        {
                          id: item.jumbo_serial_id,
                          jumbo_wastage: 0,
                          jumbo_status: "In Production",
                        },
                      ]);
                    } else {
                      console.warn("item.jumbo_serial_id is undefined");
                    }
                  })
                );

                message.success("Moved to Production");
                handleCancel();
                // apiCheck(
                //   pagination.current,
                //   pagination.pageSize,
                //   searchQuery,
                //   OrderStatusQuery,
                //   OrderStatusQueryType
                // );
                apiRefresh();
              }
            } catch (error) {
              console.error("Error updating records:", error);
            }
          }
        } else {
          message.error("Selected Jumbo Quantity is less than Need SQM!");
          return;
        }
      }
    } else if (value === "Completed") {
      let jumboAllUsed = jumboStock.reduce(
        (sum, item) => sum + Number(item.used_quantity), // Ensure it's a number
        0
      );
      let LabelAllUsed = jumboStock.reduce(
        (sum, item) => sum + Number(item.label_quantity), // Ensure it's a number
        0
      );
      console.log(
        "LabelAllUsed",
        LabelAllUsed - Number(viewOrders?.production_quantity)
      );
      let labelExtra = LabelAllUsed - Number(viewOrders?.production_quantity);

      if (
        LabelAllUsed > Number(viewOrders?.production_quantity) &&
        viewOrders.production_type == "2"
      ) {
        alert("Can't Process ! Production is more than Quantity");
        return;
      }
      if (LabelAllUsed > Number(viewOrders?.production_quantity)) {
        const isConfirmedProduction = window.confirm(
          "Used Quantity is more than Needed Quantity, Do you want to Proceed ?"
        );
        if (isConfirmedProduction) {
          const data = [
            {
              id: viewOrders.id,
              production_status: "Completed",
              pro_quantity: LabelAllUsed,
            },
          ];
          try {
            const response = await UpdateRecord("production", data);
            if (response.feedback[0].count > 0) {
              await Promise.all(
                jumboStock.map(async (item) => {
                  if (item.jumbo_serial_id !== undefined) {
                    if (item.remaining_quantity > 0) {
                      await UpdateRecord("store", [
                        {
                          id: item.jumbo_serial_id,
                          quantity: Number(item.remaining_quantity),
                          jumbo_status: "Available",
                          jumbo_wastage: Number(item.production_wastage),
                        },
                      ]);
                    } else {
                      await UpdateRecord("store", [
                        {
                          id: item.jumbo_serial_id,
                          quantity: Number(item.remaining_quantity),
                          jumbo_status: "No Stock",
                          jumbo_wastage: Number(item.production_wastage),
                        },
                      ]);
                    }
                    await UpdateRecord("production_child", [
                      {
                        id: item.id,
                        available_quantity: Number(item.available_quantity),
                        production_wastage: Number(item.production_wastage),
                        used_quantity: Number(item.used_quantity),
                        remaining_quantity: Number(item.remaining_quantity),
                        label_quantity: Number(item.label_quantity),
                      },
                    ]);
                    if (viewOrders.production_type == "1") {
                      await UpdateRecord("orders", [
                        {
                          id: viewOrders.order_id,
                          order_status: "Production Completed",
                        },
                      ]);
                    }
                  } else {
                    console.warn("item.jumbo_serial_id is undefined");
                  }
                })
              );

              const storeLabelResponse = await ReadRecord(
                `SELECT id,quantity, reserved_quantity FROM store WHERE type_id='${
                  viewOrders.label_size
                }' and type = ${productionFilter == "1" ? 3 : 5}`
              );
              const storeCoreResponse = await ReadRecord(
                `SELECT id,quantity, reserved_quantity FROM store WHERE type_id='${viewOrders.core_size}' and type = "4"`
              );
              if (storeCoreResponse.data[0].count > 0) {
                const coreQuantity =
                  Number(storeCoreResponse.data[0].rows[0].quantity) -
                  Number(viewOrders.core_quantity);
                const coreQuantityReserve =
                  Number(storeCoreResponse.data[0].rows[0].reserved_quantity) -
                  Number(viewOrders.core_quantity);
                await UpdateRecord("store", [
                  {
                    id: storeCoreResponse.data[0].rows[0].id,
                    quantity: coreQuantity,
                    reserved_quantity: coreQuantityReserve,
                  },
                ]);
              }
              if (storeLabelResponse.data[0].count > 0) {
                const storeLabelData = storeLabelResponse.data[0].rows[0];
                const labelQuantity =
                  Number(storeLabelData.quantity) +
                  Number(viewOrders.production_quantity) +
                  labelExtra;

                await UpdateRecord("store", [
                  {
                    id: storeLabelData.id,
                    quantity: labelQuantity,
                  },
                ]);
              } else {
                await CreateRecord("store", [
                  {
                    type: productionFilter == "1" ? 3 : 5,
                    type_id: Number(viewOrders.label_size),
                    quantity:
                      Number(viewOrders.production_quantity) + labelExtra,
                  },
                ]);
              }

              message.success("Production Completed");
              handleCancel();
              // apiCheck(
              //   pagination.current,
              //   pagination.pageSize,
              //   searchQuery,
              //   OrderStatusQuery,
              //   OrderStatusQueryType
              // );
              apiRefresh();
            }
          } catch (error) {
            console.error("Error updating records:", error);
          }
        }
      } else if (jumboAllUsed == Number(viewOrders?.label_quantity_sqm)) {
        const data = [
          {
            id: viewOrders.id,
            production_status: "Completed",
          },
        ];
        try {
          const response = await UpdateRecord("production", data);
          if (response.feedback[0].count > 0) {
            await Promise.all(
              jumboStock.map(async (item) => {
                if (item.jumbo_serial_id !== undefined) {
                  if (item.remaining_quantity > 0) {
                    await UpdateRecord("store", [
                      {
                        id: item.jumbo_serial_id,
                        quantity: Number(item.remaining_quantity),
                        jumbo_status: "Available",
                        jumbo_wastage: Number(item.production_wastage),
                      },
                    ]);
                  } else {
                    await UpdateRecord("store", [
                      {
                        id: item.jumbo_serial_id,
                        quantity: Number(item.remaining_quantity),
                        jumbo_status: "No Stock",
                        jumbo_wastage: Number(item.production_wastage),
                      },
                    ]);
                  }
                  await UpdateRecord("production_child", [
                    {
                      id: item.id,
                      available_quantity: Number(item.available_quantity),
                      production_wastage: Number(item.production_wastage),
                      used_quantity: Number(item.used_quantity),
                      remaining_quantity: Number(item.remaining_quantity),
                      label_quantity: Number(item.label_quantity),
                    },
                  ]);
                  if (viewOrders.production_type == "1") {
                    await UpdateRecord("orders", [
                      {
                        id: viewOrders.order_id,
                        order_status: "Production Completed",
                      },
                    ]);
                  }
                } else {
                  console.warn("item.jumbo_serial_id is undefined");
                }
              })
            );

            const storeLabelResponse = await ReadRecord(
              `SELECT id,quantity, reserved_quantity FROM store WHERE type_id='${
                viewOrders.label_size
              }' and type = ${productionFilter == "1" ? 3 : 5}`
            );
            const storeCoreResponse = await ReadRecord(
              `SELECT id,quantity, reserved_quantity FROM store WHERE type_id='${viewOrders.core_size}' and type = "4"`
            );
            if (storeCoreResponse.data[0].count > 0) {
              const coreQuantity =
                Number(storeCoreResponse.data[0].rows[0].quantity) -
                Number(viewOrders.core_quantity);
              const coreQuantityReserve =
                Number(storeCoreResponse.data[0].rows[0].reserved_quantity) -
                Number(viewOrders.core_quantity);
              await UpdateRecord("store", [
                {
                  id: storeCoreResponse.data[0].rows[0].id,
                  quantity: coreQuantity,
                  reserved_quantity: coreQuantityReserve,
                },
              ]);
            }
            if (storeLabelResponse.data[0].count > 0) {
              const storeLabelData = storeLabelResponse.data[0].rows[0];
              const labelQuantity =
                Number(storeLabelData.quantity) +
                Number(viewOrders.production_quantity);

              await UpdateRecord("store", [
                {
                  id: storeLabelData.id,
                  quantity: labelQuantity,
                },
              ]);
            } else {
              await CreateRecord("store", [
                {
                  type: productionFilter == "1" ? 3 : 5,
                  type_id: Number(viewOrders.label_size),
                  quantity: Number(viewOrders.production_quantity),
                },
              ]);
            }

            message.success("Production Completed");
            handleCancel();
            apiRefresh();
          }
        } catch (error) {
          console.error("Error updating records:", error);
        }
      } else {
        message.error("Used Quantity should be more than label Quantity");
        return;
      }
    } else if (value === "Rejected") {
      const rejected_reason = form.getFieldValue("rejected_reason");
      console.log("view", viewOrders.production_type);
      const data = [
        {
          id: viewOrders.id,
          production_status: "Rejected",
          rejected_reason: rejected_reason,
        },
      ];

      try {
        const response = await UpdateRecord("production", data);
        if (response.feedback[0].count > 0) {
          message.success("Production Rejected");
          if (viewOrders.production_type == "1") {
            const dataOrder = [
              {
                id: viewOrders.order_id,
                order_status: "Production Rejected",
                production_order_rejected_reason: rejected_reason,
              },
            ];
            try {
              const res = await UpdateRecord(
                "reject_ordersfrom_production",
                dataOrder
              );
            } catch (error) {
              console.error("Error updating records:", error);
            }
          }
          handleCancel();
          // apiCheck(
          //   pagination.current,
          //   pagination.pageSize,
          //   searchQuery,
          //   OrderStatusQuery,
          //   OrderStatusQueryType
          // );
          apiRefresh();
        }
      } catch (error) {
        console.error("Error updating records:", error);
      }
    }
  };

  const handleRejectStatus = () => {
    setIsRejectModalVisible(true);
  };
  const handleCoreQuantity = (value) => {
    form.setFieldValue("core_quantity", "");
    // console.log("vaue",value);
    ReadRecord(
      `select id,round(quantity - reserved_quantity,2) as quantity,reserved_quantity from store where ActiveStatus = '1' and status = '1' and type = '4' and type_id = '${value}'`
    ).then((response) => {
      // console.log("response", response.data[0].count > 0);
      if (response.data[0].count > 0) {
        setcorequantity({
          coreId: response.data[0].rows[0].id,
          coreReservedQuantity: response.data[0].rows[0].reserved_quantity,
          quantity: response.data[0].rows[0].quantity,
        });
        setCoreValidationStatus("");
        setCoreHelpMessage("");
      } else {
        message.error("No Core Stock Available!");
        setCoreValidationStatus("");
        setCoreHelpMessage("");
        setcorequantity({
          coreId: "",
          coreReservedQuantity: 0,
          quantity: 0,
        });
      }
    });
  };
  const handleCore = (value) => {
    console.log("Corequantity", Corequantity);
    if (value > Corequantity?.quantity) {
      setCoreValidationStatus("error");
      setCoreHelpMessage("Quantity Exceeds!");
    } else {
      setCoreValidationStatus("");
      setCoreHelpMessage("");
    }
  };
  const handleProductionChild = (value) => {
    try {
      ReadRecord(
        `select * from production_child where production_id = ${value} order by id asc`
      ).then((response) => {
        setJumboStock(response.data[0].rows);
      });
    } catch (error) {
      console.error(error);
    }
  };
  const handleView = (values) => {
    // const newRecords = { ...values };
    handleProductionChild(values.id);
    handleProductionFilter(values.type);
    const newRecords = {
      id: values.id,
      production_number: values.production_number,
      jumbo_id: values.jumbo_id,
      material_id: values.material_name,
      type: values.type === "1" ? "Label" : "Ribbon",
      label_quantity_sqm: values.production_label_quantity_sqm,
      label_size: values.label_id,
      production_date: values.production_date,
      production_quantity: values.production_label_quantity,
      pro_quantity: values.production_label_quantity,
      production_status: values.production_status,
      production_label_extra_needed_sqm:
        values.production_label_extra_needed_sqm,
      production_type: values.production_type,
      rejected_reason: values.rejected_reason,
      order_id: values.order_id,
      core_quantity: values.core_quantity,
      core_size: values.core_size,
    };
    console.log("Allfield values", newRecords);
    setIsModalVisible(true);
    setViewOrders(newRecords);
    setLabelStockNeeded(values.production_label_extra_needed_sqm);
    form.setFieldsValue(newRecords);
    if (typeFilter == "1" && values.production_status == "Awaiting Approval") {
      handleCoreQuantity(values.core_size);
    }
  };

  const handleEdit = (values) => {
    // const newRecords = { ...record };
    handleProductionFilter(values.type);
    const newRecords = {
      id: values.id,
      production_number: values.production_number,
      jumbo_id: values.jumbo_id,
      material_id: values.material_name,
      type: values.type === "1" ? "Label" : "Ribbon",
      label_quantity_sqm: values.production_label_quantity_sqm,
      label_size: values.label_id,
      production_date: values.production_date,
      production_quantity: values.production_label_quantity,
      pro_quantity: values.production_label_quantity,
      production_status: values.production_status,
      production_label_extra_needed_sqm:
        values.production_label_extra_needed_sqm,
      production_type: values.production_type,
      rejected_reason: values.rejected_reason,
    };
    console.log("All records", newRecords);
    setIsModalVisible(true);
    setEditOrders(newRecords);
    form.setFieldsValue(newRecords);
    setLabelStockNeeded(values.production_label_extra_needed_sqm);
  };

  const handleDelete = (record) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to cancel this production ?"
    );
    if (isConfirmed) {
      // console.log("Before deleting record", record.id);
      let data = {
        id: record.id,
        status: 0,
        ActiveStatus: 0,
      };
      UpdateRecord("production", [data]).then((res) => {
        message.info("Order has been cancelled");

        // apiCheck(
        //   pagination.current,
        //   pagination.pageSize,
        //   searchQuery,
        //   OrderStatusQuery,
        //   OrderStatusQueryType
        // );
        apiRefresh();

        //getOrderDetails();
        console.log("User record deleted", res);
      });
    }
  };

  const handleSearch = (value) => {
    setSearchQuery(value);
  };

  // Handle the filter action
  const handleFilter = () => {
    // Trigger API call with new date range parameters
    // apiCheck(
    //   pagination.current,
    //   pagination.pageSize,
    //   searchQuery,
    //   OrderStatusQuery,
    //   OrderStatusQueryType
    // );
    apiRefresh();
  };
  const handleDateChange = (dates) => {
    if (dates && dates.length == 2) {
      setFromDate(dates[0]);
      setToDate(dates[1]);
      apiCheck(
        1,
        pagination.pageSize,
        searchQuery,
        typeFilter,
        formatDate(dates[0]),
        formatDate(dates[1])
      );
    } else {
      // If dates are cleared, set fromDate and toDate to null
      console.log("Dates Cleared");
      setFromDate(null);
      setToDate(null);
      // Fetch data without date filter
      apiCheck(1, pagination.pageSize, searchQuery, null, null);
    }
  };
  const handleClear = () => {
    setFromDate("");
    setToDate("");
    handleFilter();
  };
  const handleJumboTypeChange = async (value) => {
    console.log("value", value);
    try {
      const res = await ReadRecord(
        `SELECT t1.id,t1.material_id,t1.type,t2.material_name FROM jumbo_master t1 join material_master t2 ON t1.material_id=t2.id where t1.id='${value}' order by t1.id desc limit 1`
      );
      console.log("created by value", res.data[0].rows[0]);

      if (res.data[0].count > 0) {
        setSelectedJumboType(true);
        form.setFieldValue(
          "type",
          res.data[0].rows[0].type == "1"
            ? "Label"
            : res.data[0].rows[0].type == "2"
            ? "Ribbon"
            : ""
        );
        form.setFieldValue(
          "material_id",
          res.data[0].rows[0].material_name || ""
        );
        ReadRecord(
          `select * from jumbo_store where jumbo_master_id = '${res.data[0].rows[0].id}' and ActiveStatus = 1`
        ).then((response) => {
          setJumboSerials(response.data[0]?.rows || []);
        });
      } else {
        form.setFieldValue("type", "");
        form.setFieldValue("material_id", "");
      }
    } catch (error) {
      console.error("Error fetching jumbo data:", error);
    }
  };
  const handleNextProduction = () => {
    setSelectedRows([]);
    form.validateFields();
    const production_date = form.getFieldValue("production_date");
    const jumbo_id = form.getFieldValue("jumbo_id");
    const label_size = form.getFieldValue("label_size");
    // const type = form.getFieldValue("type");
    const type = productionFilter;
    const production_quantity = form.getFieldValue("production_quantity");
    // console.log("vakues", {
    //   jumbo_id: jumbo_id,
    //   label_size: label_size,
    //   production_quantity: production_quantity,
    // });
    if (
      jumbo_id == "" ||
      jumbo_id === undefined ||
      label_size == "" ||
      label_size === undefined ||
      production_quantity == "" ||
      production_quantity === undefined
    ) {
      return;
    } else {
      // lwq
      // =ROUND((((N146+5)*(O146+3)/645.16)*P146)/1550,0)

      //   console.log("selected", selected);
      let label_quantity_sqm = 0;
      if (type == "1") {
        const selected = LabelOptions.find(
          (option) => option.id === label_size
        );
        console.log("Label");
        label_quantity_sqm = Math.round(
          ((((Number(selected.label_width) + 5) *
            (Number(selected.label_length) + 3)) /
            645.16) *
            Number(production_quantity)) /
            1550
        );
      } else {
        const selected = ribbonOptions.find(
          (option) => option.id === label_size
        );
        console.log("Ribbon");
        label_quantity_sqm = Math.round(
          ((selected.ribbon_width * selected.ribbon_length) / 1000) *
            production_quantity
        );
      }

      console.log("selected", label_quantity_sqm);
      form.setFieldValue("label_quantity_sqm", label_quantity_sqm);
      if (label_quantity_sqm <= 0) {
        return message.error("Label SQM is very less for production!");
      }
      ReadRecord(
        `SELECT t1.id,t3.jumbo_type, t2.jumbo_serial_no, t1.quantity AS available_quantity ,t1.store_length,t1.store_width,t1.CreatedDate FROM store t1 join jumbo_store t2 ON t1.type_id=t2.id join jumbo_master t3 ON t2.jumbo_master_id=t3.id WHERE t1.status = 1 and t1.ActiveStatus = 1 and t1.type=1 and t1.jumbo_status = 'Available' and t3.id='${jumbo_id}' and round(t1.quantity) > 0 order by t1.id asc;`
      ).then((response) => {
        // console.log("response", response.data[0].rows);
        if (response.data[0].count > 0) {
          let label_stock_need = label_quantity_sqm;
          let Extra = 0;
          const jumbo_stock = response.data[0].rows
            .map((item) => {
              if (label_stock_need > 0) {
                Extra = Math.round(Extra + Number(item.available_quantity));
                item.remaining_quantity = Number(item.available_quantity);
                item.jumbo_serial_id = item.id;
                item.jumbo_serial_length = item.store_length;
                item.jumbo_serial_width = item.store_width;
                item.production_id = viewOrders.id;
                // item.actual_remaining_quantity =
                //   item.available_quantity - label_stock_need;
                item.production_wastage = 0;
                item.used_quantity = 0;
                delete item.store_length;
                delete item.store_width;
                delete item.id;
                return item;
                // if (label_stock_need > item.available_quantity) {
                //   label_stock_need = label_stock_need - item.available_quantity;
                //   item.remaining_quantity = 0;
                //   item.jumbo_serial_id = item.id;
                //   item.jumbo_serial_length = item.store_length;
                //   item.jumbo_serial_width = item.store_width;
                //   item.production_id = viewOrders.id;
                //   item.actual_remaining_quantity =
                //     item.available_quantity - label_stock_need;
                //   item.production_wastage = 0;
                //   delete item.store_length;
                //   delete item.store_width;
                //   delete item.id;
                //   return item;
                // } else {
                //   item.remaining_quantity =
                //     item.available_quantity - label_stock_need;
                //   item.actual_remaining_quantity =
                //     item.available_quantity - label_stock_need;
                //   item.available_quantity = label_stock_need;
                //   label_stock_need = 0;
                //   item.jumbo_serial_id = item.id;
                //   item.jumbo_serial_length = item.store_length;
                //   item.jumbo_serial_width = item.store_width;
                //   item.production_id = viewOrders.id;
                //   item.production_wastage = 0;
                //   delete item.store_length;
                //   delete item.store_width;
                //   delete item.id;
                //   return item;
                // }
              }
            })
            .filter((item) => item != undefined);
          console.log("jumbo_stock", label_stock_need, Extra);
          if (Number(label_stock_need) > Number(Extra)) {
            setLabelStockNeeded(Number(label_stock_need) - Number(Extra));
            message.error("Not enough Stock to Process this Production!");
            return;
          } else {
            setJumboStock(jumbo_stock);
            setLabelStockNeeded(0);
          }
        } else {
          message.error("No stock available for the select Jumbo!");
        }
      });
    }
  };
  const handleLabel = () => {
    form.setFieldValue("production_quantity", "");
    form.setFieldValue("label_quantity_sqm", "");
  };
  const options = [
    {
      label: "Order",
      value: "1",
    },
    {
      label: "Direct",
      value: "2",
    },
  ];
  const optionsType = [
    {
      label: "Ribbon",
      value: "2",
    },
    {
      label: "Label",
      value: "1",
    },
  ];
  const apiRefresh = () => {
    apiCheck(
      pagination.current,
      pagination.pageSize,
      searchQuery,
      typeFilter,
      formatDate(fromDate),
      formatDate(toDate),
      OrderStatusQuery,
      OrderStatusQueryType
    );
  };
  const handleTypeFilter = (value) => {
    // console.log("keyfilter", value.target.value);
    settypeFilter(value.target.value);
    apiCheck(
      1,
      pagination.pageSize,
      searchQuery,
      value.target.value,
      formatDate(fromDate),
      formatDate(toDate),
      OrderStatusQuery,
      OrderStatusQueryType
    );
  };
  const handleProductionFilter = (value) => {
    setProductionFilter(value);
    getJumboDetails(value);
    form.resetFields();
  };
  // Helper function to handle floating-point arithmetic more accurately
  const preciseSubtract = (a, b, c) => {
    return parseFloat((a - b - c).toFixed(2));
  };

  const handleUsedQuantity = (value, index) => {
    console.log("viewOrders", value);
    let label_quantity_sqm = 0;
    if (viewOrders.type == "Label") {
      const selected = LabelOptions.find(
        (option) => option.id === viewOrders.label_size
      );
      label_quantity_sqm = Math.round(
        ((((Number(selected.label_width) + 5) *
          (Number(selected.label_length) + 3)) /
          645.16) *
          Number(value)) /
          1550
      );
      console.log("label_quantity_sqm", label_quantity_sqm);
    } else {
      const selected = ribbonOptions.find(
        (option) => option.id === viewOrders.label_size
      );
      console.log("Ribbon");
      label_quantity_sqm = Math.round(
        ((selected.ribbon_width * selected.ribbon_length) / 1000) *
          Number(value)
      );
    }
    // console.log(value, index);
    setJumboStock((prev) => {
      console.log(prev[index]);
      const availableQuantity = Number(prev[index].available_quantity) || 0;
      const WastageQuantity = Number(prev[index].production_wastage) || 0;
      let remainingQuantity = Number(prev[index].remaining_quantity) || 0;

      // Use precise subtraction
      remainingQuantity = preciseSubtract(
        availableQuantity,
        label_quantity_sqm,
        WastageQuantity
      );
      // console.log(remainingQuantity);

      if (
        label_quantity_sqm >
        preciseSubtract(availableQuantity, WastageQuantity, 0)
      ) {
        message.warning("Insufficient quantity!");
      } else {
        prev[index].remaining_quantity = remainingQuantity;
        prev[index].used_quantity = label_quantity_sqm;
        prev[index].label_quantity = value;
      }
      return [...prev];
    });
  };

  const handleWastage = (value, index) => {
    setJumboStock((prev) => {
      console.log(prev[index]);
      const availableQuantity = Number(prev[index].available_quantity) || 0;
      let remainingQuantity = Number(prev[index].remaining_quantity) || 0;
      let usedQuantity = Number(prev[index].used_quantity) || 0;

      // Use precise subtraction
      remainingQuantity = preciseSubtract(
        availableQuantity,
        value,
        usedQuantity
      );
      console.log(remainingQuantity);

      if (value > preciseSubtract(availableQuantity, usedQuantity, 0)) {
        message.warning("Insufficient quantity!");
      } else {
        prev[index].remaining_quantity = remainingQuantity;
        prev[index].production_wastage = value;
      }
      return [...prev];
    });
  };

  const handleCheckboxChange = (checked, rowData) => {
    // console.log("order", rowData);

    setSelectedRows((prevSelectedRows) => {
      if (checked) {
        // Add the row data to the selectedRows array
        return [...prevSelectedRows, rowData];
      } else {
        // Remove the row data from the selectedRows array
        return prevSelectedRows.filter(
          (row) => row.jumbo_serial_no !== rowData.jumbo_serial_no
        );
      }
    });
    setJumboStock((prevSelectedRows) => {
      let id = rowData.jumbo_serial_id;

      prevSelectedRows.map((item) => {
        if (item.jumbo_serial_id === rowData.jumbo_serial_id) {
          if (checked) {
            item.checked = true;
          } else {
            item.checked = false;
          }
        }
      });
      return [...prevSelectedRows];
    });
  };
  const handleTermSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };
  const handleTermClear = (e) => {
    setSearchTerm("");
  };
  const filteredStock = jumboStock?.filter((order) => {
    const serialMatch = order?.jumbo_serial_no
      ?.toLowerCase()
      .includes(searchTerm);
    const lengthMatch = order?.jumbo_serial_length
      ?.toString()
      .includes(searchTerm);
    const widthMatch = order?.jumbo_serial_width
      ?.toString()
      .includes(searchTerm);
    return serialMatch || lengthMatch || widthMatch;
  });
  const handleTableChange = (pagination, filters) => {
    console.log("filters", filters);
    let query = "";
    let queryType = "";
    const filterClauses = [];
    if (
      filters?.material_name != null ||
      filters?.ordsts != null ||
      filters?.typee != null
    ) {
      setFilteredInfo(filters);
    } else {
      setFilteredInfo({});
    }
    if (filters.ordsts && filters.ordsts.length > 0) {
      const orderStatusInClause = filters.ordsts
        .filter((item) => item)
        .map((item) => `'${item}'`)
        .join(", ");
      if (orderStatusInClause) {
        filterClauses.push(`t1.production_status IN (${orderStatusInClause})`);
      }
    }
    if (filters.material_name && filters.material_name.length > 0) {
      const MaterialInClause = filters.material_name
        .filter((item) => item)
        .map((item) => `'${item}'`)
        .join(", ");
      if (MaterialInClause) {
        filterClauses.push(`t5.material_name IN (${MaterialInClause})`);
      }
    }
    if (filters.typee && filters.typee.length > 0) {
      const ordertypeInClause = filters.typee
        .map((item) => `'${item}'`)
        .join(", ");
      queryType = `AND t2.type IN (${ordertypeInClause})`;
      setOrderStatusQueryType(queryType);
    }
    if (filterClauses.length > 0) {
      query = `AND ${filterClauses.join(" AND ")}`;
    }
    setOrderStatusQuery(query);

    return apiCheck(
      pagination.current,
      pagination.pageSize,
      searchQuery,
      typeFilter,
      formatDate(fromDate),
      formatDate(toDate),
      query,
      queryType
    );
  };
  const clearFilters = () => {
    setFilteredInfo({});
    setFromDate("");
    setToDate("");
    setSearchQuery("");
    setOrderStatusQuery("");
    setOrderStatusQueryType("");
    setTimeout(() => {
      apiCheck(
        pagination.current,
        pagination.pageSize,
        "",
        typeFilter,
        "",
        "",
        "",
        ""
      );
    }, 0);
  };

  return (
    <div>
      <>
        <div style={{ display: "flex", alignItems: "center" }}>
          <p
            style={{
              color: "#333",
              marginRight: "auto",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            Production
          </p>
          {filteredInfo && Object.keys(filteredInfo).length > 0 && (
            <Button
              onClick={clearFilters}
              style={{ borderColor: "red", color: "red" }}
            >
              Clear Filters
            </Button>
          )}
          &nbsp;
          <Radio.Group
            options={options}
            onChange={(e) => handleTypeFilter(e)}
            value={typeFilter}
            optionType="button"
            buttonStyle="solid"
          />
          &nbsp;
          <RangePicker
            value={[fromDate, toDate]}
            onChange={handleDateChange}
            style={{ width: 230 }}
            allowClear={true}
          />
          &nbsp;
          <Input
            placeholder="Search"
            value={searchQuery}
            allowClear={handleClear}
            style={{ width: 200, margin: "0 5px 0px 0px" }}
            prefix={<SearchOutlined />}
            onChange={(e) => handleSearch(e.target.value)}
          />
          {(harnetRoleAccess && harnetRoleAccess.includes("19")) ||
          (userRoleHarnet && userRoleHarnet === "1") ? (
            <Button type="primary" onClick={showModal} icon={<PlusOutlined />}>
              Create Production Request
            </Button>
          ) : (
            ""
          )}
        </div>
        {/* <Table dataSource={filteredUsers} scroll={{y:400}} columns={columns} rowClassName={(record, index) => */}
        <Table
          // loading={!orders.length > 0}
          onChange={handleTableChange}
          size="small"
          bordered
          dataSource={orders}
          columns={columns}
          rowClassName={(record, index) =>
            index % 2 === 1 ? "even-row" : "odd-row"
          }
          scroll={{
            x: "max-content",
          }}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            // onChange: (page, pageSize) => {
            //   apiCheck(
            //     page,
            //     pageSize,
            //     searchQuery,
            //     typeFilter,
            //     formatDate(fromDate),
            //     formatDate(toDate)
            //   );
            // },
            total: pagination.total,
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
      </>
      <Modal
        title={
          <span>
            {editOrders
              ? `Edit Production - ${editOrders?.production_number}`
              : viewOrders
              ? `View Production - ${viewOrders?.production_number}`
              : "Create New Production"}
            &nbsp;
            <span style={{ marginLeft: "20%" }}>
              <Radio.Group
                options={optionsType}
                // onChange={(e) => handleTypeFilter(e)}
                onChange={(e) => handleProductionFilter(e.target.value)}
                value={productionFilter}
                optionType="button"
                buttonStyle="solid"
                disabled={viewOrders || editOrders}
              />
            </span>
          </span>
        }
        id="ProductionModal"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={1050}
        centered
        maskClosable={false}
      >
        <hr />
        {/* &nbsp; */}
        <Form
          size="small"
          form={form}
          name="createproductionform"
          onFinish={onFinish}
          layout="horizontal"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          id="productionform"
          className="productionform"
        >
          <Row gutter={16} style={{ height: "28px" }}>
            <Col span={8}>
              {/*<Form.Item
                label="User name"
                name="username"
                id="username"
                className="usernname"
                initialValue={loggedinuser}
              >
                <Input disabled />
              </Form.Item> */}
              <Form.Item
                // style={{height:'2px'}}
                label="Jumbo type"
                name="jumbo_id"
                id="jum_name"
                className="jum_name"
                rules={[
                  { required: true, message: "Please select Jumbo_type!" },
                ]}
                // wrapperCol={{span:52}}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder="Select"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  disabled={viewOrders || editOrders || productionFilter === ""}
                  onChange={handleJumboTypeChange}
                >
                  {JumboOptions &&
                    JumboOptions.map((loc) => (
                      <Select.Option key={loc.id} value={loc.id}>
                        {loc.jumbo_type}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}></Col>
            <Col span={8}>
              <Form.Item
                // style={{height:'2px'}}
                label="Date"
                name="production_date"
                id="production_date"
                className="production_date"
                initialValue={orderDate.toLocaleDateString("en-CA")}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          {/* <Row gutter={16}>
            <Col span={12}></Col>
            <Col span={12}></Col>
          </Row> */}
          <Row gutter={16} style={{ height: "28px" }}>
            <Col span={8}>
              <Form.Item
                label="Material"
                name="material_id"
                id="mat_name"
                className="mat_name"
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <div>
                <Form.Item
                  label={productionFilter == "1" ? "Label Size" : "Ribbon Size"}
                  name="label_size"
                  id="label_size"
                  className="label_size"
                  rules={[
                    { required: true, message: "Please select Label Size" },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    filterOption={(input, option) => {
                      const optionText = option.children
                        .join(" ")
                        .toLowerCase();
                      return optionText.includes(input.toLowerCase());
                    }}
                    onChange={handleLabel}
                    placeholder="Select the label size"
                    disabled={
                      viewOrders || editOrders || productionFilter === ""
                    }
                  >
                    {productionFilter == "1"
                      ? LabelOptions.map((label) => (
                          <Select.Option key={label.id} value={label.id}>
                            {label.label_width} x {label.label_length}{" "}
                            {label.label_measure}
                          </Select.Option>
                        ))
                      : ribbonOptions.map((label) => (
                          <Select.Option key={label.id} value={label.id}>
                            {label.ribbon_width} x {label.ribbon_length}{" "}
                            {label.ribbon_measure}
                          </Select.Option>
                        ))}
                  </Select>
                </Form.Item>
              </div>
            </Col>
            <Col span={8}>
              <div>
                <Form.Item
                  label="Quantity"
                  name="production_quantity"
                  id="productionquantity"
                  className="productionquantity"
                  rules={[{ required: true, message: "Enter Quantity!" }]}
                >
                  <InputNumber
                    type="number"
                    changeOnWheel={false}
                    min={1}
                    disabled={
                      viewOrders ||
                      (!viewOrders && !editOrders && productionFilter === "")
                    }
                    controls={false}
                  />
                </Form.Item>
              </div>
            </Col>
            {/* <Col span={12}>
              <Form.Item label="Type" name="type" id="type" className="type">
                <Input disabled />
              </Form.Item>
            </Col> */}
          </Row>
          {/* <div style={{ display: "grid", gridTemplateColumns: "50% 35% 15%" }}> */}
          <Row gutter={16} style={{ height: "28px" }}>
            {viewOrders &&
              (viewOrders?.production_status === "Awaiting Approval" ||
                viewOrders?.production_status === "In Process" ||
                viewOrders?.production_status === "Completed") && (
                <>
                  <Col span={8}>
                    <Form.Item
                      label="Core size"
                      name="core_size"
                      id="core_type"
                      className="core_type"
                      rules={[
                        { required: true, message: "Please select Core type" },
                      ]}
                    >
                      <Select
                        showSearch
                        allowClear
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        placeholder="Select"
                        // options={selectedCoreId && selectedCoreId.map(cus => ({ value: cus.id, label: cus.core_size }))}
                        disabled={
                          viewOrders?.production_status !=
                            "Awaiting Approval" ||
                          typeFilter === "1" ||
                          typeFilter === ""
                        }
                        onChange={(e) => {
                          handleCoreQuantity(e);
                        }}
                      >
                        {selectedCoreId &&
                          selectedCoreId.map((record) => (
                            <Select.Option key={record.id} value={record.id}>
                              {record.core_type +
                                " - " +
                                record.core_dia +
                                " " +
                                record.core_dia_measure +
                                " x " +
                                record.core_length +
                                " " +
                                record.core_length_measure}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label={
                        <span>
                          <span
                            title="Core Quantity"
                            style={{ color: "#ff4d4f", fontSize: 16 }}
                          >
                            *
                          </span>{" "}
                          Core Quantity
                        </span>
                      }
                      name="core_quantity"
                      id="core_quantity"
                      className="core_quantity"
                      // rules={[{ required: true, message: "Core Quantity!" }]}
                      validateStatus={coreValidationStatus}
                      help={coreHelpMessage}
                    >
                      <InputNumber
                        type="number"
                        disabled={
                          viewOrders?.production_status !=
                            "Awaiting Approval" || typeFilter === ""
                        }
                        changeOnWheel={false}
                        controls={false}
                        onChange={(e) => handleCore(e)}
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
            <Col span={8}>
              {viewOrders &&
                (viewOrders?.production_status === "Awaiting Approval" ||
                  viewOrders?.production_status === "In Process" ||
                  viewOrders?.production_status === "Completed") && (
                  <div>
                    <Form.Item
                      label="Total SQM"
                      name="label_quantity_sqm"
                      id="productionquantity"
                      className="productionquantity"
                    >
                      <InputNumber min={1} disabled />
                    </Form.Item>
                  </div>
                )}
            </Col>
          </Row>
          {/* </div> */}
          {viewOrders &&
            (viewOrders?.production_status === "Awaiting Approval" ||
              viewOrders?.production_status === "In Process" ||
              viewOrders?.production_status === "Completed") && (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    //   alignItems: "center",
                    height: "28px",
                  }}
                >
                  {viewOrders &&
                    viewOrders?.production_status === "Awaiting Approval" && (
                      <div
                        style={{
                          flex: 1,
                          display: "flex",
                          justifyContent: "flex-end",
                          // height:'28px'
                        }}
                      >
                        <Form.Item wrapperCol={{ offset: 14, span: 10 }}>
                          <Button
                            type="primary"
                            htmlType="button"
                            id="locationsubmit"
                            className="locationsubmit"
                            onClick={handleNextProduction}
                            style={{
                              backgroundColor: "lightgreen",
                              color: "grey",
                            }}
                          >
                            Click here to Load Stock details
                          </Button>
                        </Form.Item>
                      </div>
                    )}
                  {viewOrders &&
                    (viewOrders?.production_status === "Awaiting Approval" ||
                      viewOrders?.production_status === "In Process" ||
                      viewOrders?.production_status === "Completed") && (
                      <div
                        style={{
                          flex: 1,
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Typography.Text
                          style={{ fontWeight: "bold", color: "red" }}
                        >
                          {/* {jumboStock.length > 0 &&
                            `Extra Needed Stock : ${labelStockNeeded}`} */}
                        </Typography.Text>
                      </div>
                    )}
                </div>
                <hr></hr>
              </>
            )}
          {viewOrders &&
            viewOrders?.production_status === "Awaiting Approval" && (
              <div>
                <Input
                  placeholder="Search serial, width or length"
                  allowClear={handleTermClear}
                  style={{ width: 300, margin: "0 5px 5px 0px" }}
                  prefix={<SearchOutlined />}
                  onChange={handleTermSearch}
                  disabled={!jumboStock?.length > 0}
                />
              </div>
            )}

          {viewOrders &&
            (viewOrders?.production_status === "Awaiting Approval" ||
              viewOrders?.production_status === "In Process" ||
              viewOrders?.production_status === "Completed") && (
              <div
                style={{
                  height: "255px",
                  overflowX: "scroll",
                  overflowX: "hidden",
                  width: "100%",
                }}
              >
                {/* <Spin spinning={!jumboStock.length > 0}> */}
                <table
                  style={{
                    width: "100%",
                    border: "1px solid #ccc",
                    borderCollapse: "collapse",
                  }}
                >
                  <thead>
                    <tr
                      style={{
                        backgroundColor: "#f0f0f0",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                      }}
                    >
                      {viewOrders?.production_status ===
                        "Awaiting Approval" && (
                        <th
                          style={{ border: "1px solid #ccc", padding: "3px" }}
                        >
                          Select
                        </th>
                      )}
                      <th style={{ border: "1px solid #ccc", padding: "3px" }}>
                        Sl No.
                      </th>
                      {viewOrders?.production_status ===
                        "Awaiting Approval" && (
                        <th
                          style={{ border: "1px solid #ccc", padding: "3px" }}
                        >
                          Date
                        </th>
                      )}
                      <th style={{ border: "1px solid #ccc", padding: "8px" }}>
                        Jumbo Serial No
                      </th>
                      <th style={{ border: "1px solid #ccc", padding: "8px" }}>
                        Width x Length
                      </th>
                      {(viewOrders?.production_status === "In Process" ||
                        viewOrders?.production_status ===
                          "Awaiting Approval") && (
                        <th
                          style={{ border: "1px solid #ccc", padding: "8px" }}
                        >
                          Available Quantity
                        </th>
                      )}

                      {(viewOrders?.production_status === "In Process" ||
                        viewOrders?.production_status === "Completed") && (
                        <th
                          style={{ border: "1px solid #ccc", padding: "8px" }}
                        >
                          {viewOrders.type == "Label"
                            ? "Label Produced"
                            : "Ribbon Produced"}
                        </th>
                      )}
                      {(viewOrders?.production_status === "In Process" ||
                        viewOrders?.production_status === "Completed") && (
                        <th
                          style={{ border: "1px solid #ccc", padding: "8px" }}
                        >
                          Used Qty SQM
                        </th>
                      )}
                      {viewOrders?.production_status === "In Process" && (
                        <>
                          <th
                            style={{ border: "1px solid #ccc", padding: "8px" }}
                          >
                            Wastage SQM
                          </th>
                          <th
                            style={{ border: "1px solid #ccc", padding: "8px" }}
                          >
                            Remaining Quantity SQM
                          </th>
                        </>
                      )}
                      {viewOrders?.production_status === "Completed" && (
                        <>
                          <th
                            style={{ border: "1px solid #ccc", padding: "8px" }}
                          >
                            Wastage
                          </th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {filteredStock?.map((order, index) => (
                      <tr key={index}>
                        {viewOrders?.production_status ===
                          "Awaiting Approval" && (
                          <td
                            style={{
                              border: "1px solid #ccc",
                              padding: "3px",
                              textAlign: "center",
                            }}
                          >
                            <Checkbox
                              checked={order.checked}
                              onChange={(e) =>
                                handleCheckboxChange(e.target.checked, order)
                              }
                              disabled={
                                viewOrders?.production_status === "Completed" ||
                                viewOrders?.production_status === "In Process"
                              }
                            />
                          </td>
                        )}
                        <td
                          style={{
                            border: "1px solid #ccc",
                            padding: "3px",
                            textAlign: "center",
                          }}
                        >
                          {index + 1}
                        </td>
                        {viewOrders?.production_status ===
                          "Awaiting Approval" && (
                          <td
                            style={{
                              border: "1px solid #ccc",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            {order?.CreatedDate}
                          </td>
                        )}
                        <td
                          style={{
                            border: "1px solid #ccc",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          {order?.jumbo_serial_no}
                        </td>
                        <td
                          style={{
                            border: "1px solid #ccc",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          {order?.jumbo_serial_width} x{" "}
                          {order?.jumbo_serial_length}
                        </td>
                        {(viewOrders?.production_status === "In Process" ||
                          viewOrders?.production_status ===
                            "Awaiting Approval") && (
                          <td
                            style={{
                              border: "1px solid #ccc",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            {order?.available_quantity}
                          </td>
                        )}
                        {viewOrders?.production_status === "In Process" && (
                          <>
                            <td
                              style={{
                                border: "1px solid #ccc",
                                padding: "8px",
                                textAlign: "center",
                              }}
                            >
                              <InputNumber
                                value={order?.label_quantity}
                                min={0}
                                changeOnWheel={false}
                                controls={false}
                                placeholder="0"
                                size="small"
                                type="number"
                                onChange={(value) =>
                                  handleUsedQuantity(value, index)
                                }
                              />
                            </td>
                            <td
                              style={{
                                border: "1px solid #ccc",
                                padding: "8px",
                                textAlign: "center",
                              }}
                            >
                              {order?.used_quantity}
                              {/* <InputNumber
                              value={order?.used_quantity}
                              min={0}
                              changeOnWheel={false}
                              controls={false}
                              placeholder="0"
                              size="small"
                              type="number"
                              onChange={(value) =>
                                handleUsedQuantity(value, index)
                              }
                            /> */}
                            </td>
                          </>
                        )}
                        {viewOrders?.production_status === "Completed" && (
                          <>
                            <td
                              style={{
                                border: "1px solid #ccc",
                                padding: "8px",
                                textAlign: "center",
                              }}
                            >
                              {order?.label_quantity}
                            </td>
                            <td
                              style={{
                                border: "1px solid #ccc",
                                padding: "8px",
                                textAlign: "center",
                              }}
                            >
                              {order?.used_quantity}
                            </td>
                          </>
                        )}
                        {viewOrders?.production_status === "In Process" && (
                          <>
                            <td
                              style={{
                                border: "1px solid #ccc",
                                padding: "8px",
                                textAlign: "center",
                              }}
                            >
                              {viewOrders?.production_status ===
                                "In Process" && (
                                <InputNumber
                                  value={order?.production_wastage}
                                  min={0}
                                  max={order?.actual_remaining_quantity}
                                  changeOnWheel={false}
                                  controls={false}
                                  placeholder="0"
                                  size="small"
                                  type="number"
                                  onChange={(value) =>
                                    handleWastage(value, index)
                                  }
                                />
                              )}
                            </td>
                            <td
                              style={{
                                border: "1px solid #ccc",
                                padding: "8px",
                                textAlign: "center",
                              }}
                            >
                              {order?.remaining_quantity}
                            </td>
                          </>
                        )}
                        {viewOrders?.production_status === "Completed" && (
                          <td
                            style={{
                              border: "1px solid #ccc",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            {order?.production_wastage}
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>

                {/* </Spin> */}
              </div>
            )}
          {/* <div
            style={{ display: "grid", gridTemplateColumns: "36% 22% 22% 22%" }}
          >
            <div>
              <Typography.Text style={{ fontWeight: "bold" }}>
                Jumbo Serial No
              </Typography.Text>
            </div>
            <div>
              <Typography.Text style={{ fontWeight: "bold" }}>
                Length
              </Typography.Text>
            </div>
            <div>
              <Typography.Text style={{ fontWeight: "bold" }}>
                Width
              </Typography.Text>
            </div>
            <div>
              <Typography.Text style={{ fontWeight: "bold" }}>
                Quantity
              </Typography.Text>
            </div>
          </div>
          <div style={{ height: "200px",
                  overflowX: "scroll",
                  overflowX: "hidden", }}>
            {formItems.map((item, index) => (
              <div
                key={item.id}
                style={{
                  display: "grid",
                  gridTemplateColumns: "36% 22% 22% 22%",
                //   overflowY: "scroll",
                }}
              >
                <div>
                  <Form.Item
                    name={`expected_delivery_date_${item.id}`}
                    id={`exp_date_${item.id}`}
                    className={`exp_date_${item.id}`}
                  >
                    <Input readOnly />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    name={`expected_delivery_date_${item.id}`}
                    id={`exp_date_${item.id}`}
                    className={`exp_date_${item.id}`}
                  >
                    <Input readOnly />
                  </Form.Item>
                </div>
                <Col>
                  <Form.Item
                    name={`expected_delivery_date_${item.id}`}
                    id={`exp_date_${item.id}`}
                    className={`exp_date_${item.id}`}
                  >
                    <Input readOnly />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name={`expected_delivery_date_${item.id}`}
                    id={`exp_date_${item.id}`}
                    className={`exp_date_${item.id}`}
                  >
                    <Input readOnly />
                  </Form.Item>
                </Col>
              </div>
            ))}
          </div> */}

          {/* <Button onClick={handleAddFormItem} title="+">
            Add
          </Button> */}
          {viewOrders?.production_status === "Rejected" && (
            <Typography.Text
              style={{ color: "red", fontWeight: "bold" }}
            >{`Rejected Reason - ${viewOrders?.rejected_reason}`}</Typography.Text>
          )}
          <hr></hr>
          {viewOrders ? null : (
            <Form.Item wrapperCol={{ offset: 10, span: 10 }}>
              <Button
                size="middle"
                type="primary"
                htmlType="submit"
                id="locationsubmit"
                className="locationsubmit"
              >
                {editOrders ? "Update" : "New Production Request"}
              </Button>
            </Form.Item>
          )}
          {!viewOrders ? null : (
            <Form.Item wrapperCol={{ offset: 7, span: 100 }}>
              {(harnetRoleAccess && harnetRoleAccess.includes("23")) ||
              (userRoleHarnet && userRoleHarnet === "1") ? (
                <Button
                  size="middle"
                  type="primary"
                  style={{ background: "green", color: "white" }}
                  disabled={
                    ["Rejected", "In Process", "Completed"].includes(
                      viewOrders.production_status
                    ) || jumboStock.length == 0
                  }
                  htmlType="button"
                  id="productionApprove"
                  className="productionApprove"
                  onClick={() => {
                    handleProductionStatus("In Process");
                  }}
                >
                  Start Production
                </Button>
              ) : (
                ""
              )}
              &nbsp;&nbsp;
              {(harnetRoleAccess && harnetRoleAccess.includes("24")) ||
              (userRoleHarnet && userRoleHarnet === "1") ? (
                <Button
                  size="middle"
                  type="primary"
                  style={{ background: "brown", color: "white" }}
                  disabled={["Rejected", "In Process", "Completed"].includes(
                    viewOrders.production_status
                  )}
                  htmlType="button"
                  id="Rejectproduction"
                  className="Rejectproduction"
                  onClick={() => {
                    handleRejectStatus();
                  }}
                >
                  Reject
                </Button>
              ) : (
                ""
              )}
              <Modal
                title="Reject Order"
                id="rejectModal"
                open={isRejectModalVisible}
                onCancel={handleNewCancel}
                footer={null}
                className="newAntModelContent"
              >
                <Form size="large" form={form}>
                  <Form.Item label="Reject Reason" name="rejected_reason">
                    <Input.TextArea />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      size="middle"
                      type="primary"
                      htmlType="button"
                      onClick={() => {
                        handleProductionStatus("Rejected");
                      }}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Modal>
              &nbsp;&nbsp;
              {(harnetRoleAccess && harnetRoleAccess.includes("25")) ||
              (userRoleHarnet && userRoleHarnet === "1") ? (
                <Button
                  size="middle"
                  type="primary"
                  style={{ background: "orange", color: "white" }}
                  disabled={[
                    "Rejected",
                    "Awaiting Approval",
                    "Completed",
                  ].includes(viewOrders.production_status)}
                  htmlType="button"
                  id="productionCompleted"
                  className="productionCompleted"
                  onClick={() => {
                    handleProductionStatus("Completed");
                  }}
                >
                  Production Completed
                </Button>
              ) : (
                ""
              )}
            </Form.Item>
          )}
        </Form>
      </Modal>
    </div>
  );
};
export default Production;
