import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  theme,
  Select,
  message,
  Row,
  Col,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  LoadingOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  CreateRecord,
  ReadRecord,
  UpdateRecord,
} from "../../Service/API/ApiService";
import debounce from "lodash/debounce";
import { isNullOrUndef } from "chart.js/helpers";

const UserMaster = () => {
  const userid = localStorage.getItem("userID");
  const [users, setUsers] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editUserData, setEditUserData] = useState(null);
  const [ViewUserData, setViewUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [roleOptions, setRoleOptions] = useState([]);
  const [roleAccessOptions, setRoleAccessOptions] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("Active");
  const [usernameValidationStatus, setUsernameValidationStatus] = useState("");
  const [usernameHelpMessage, setUsernameHelpMessage] = useState("");
  const [emailValidationStatus, setEmailValidationStatus] = useState("");
  const [emailHelpMessage, setEmailHelpMessage] = useState("");
  const [mobileValidationStatus, setMobileValidationStatus] = useState("");
  const [mobileHelpMessage, setMobileHelpMessage] = useState("");

  const [searchQuery, setSearchQuery] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  useEffect(() => {
    apiCheck(pagination.current, pagination.pageSize, searchQuery);
    roleListData();
    roleAccessListData();
  }, [searchQuery]);

  async function apiCheck(page, pageSize, search = "") {
    let searchFilter = "";
    let offset = (page - 1) * pageSize;
    if (search != "") {
      if (search.toLowerCase().trim() === "active") {
        searchFilter = `AND t1.status = 1`;
      } else if (search.toLowerCase().trim() === "inactive") {
        searchFilter = `AND t1.status = 0`;
      } else {
        searchFilter = ` AND (t1.username like '%${search}%' OR t1.id like '%${search}%' OR t1.mobile_no like '%${search}'
       OR t2.role_name like '%${search}' OR t1.email like '%${search}'  ) `;
      }
      offset = 0;
    }

    let searchData = `SELECT t1.*, t2.role_name 
    FROM user_master t1 
    JOIN roles t2 ON t1.role_id = t2.role_id 
    where t1.ActiveStatus=1 ${searchFilter} order by t1.status DESC, t1.id desc limit ${pageSize} offset ${offset}`;
    let searchDataCount = `SELECT COUNT(*) as TotalCount
    FROM user_master t1 
    JOIN roles t2 ON t1.role_id = t2.role_id 
    WHERE t1.ActiveStatus = 1 ${searchFilter}`;
    const apiRequest1 = ReadRecord(searchData).then((res) => {
      console.log("apiRequest1", res);
      return res;
    });
    const apiRequest2 = ReadRecord(searchDataCount).then((res) => {
      console.log("apiRequest2", res);
      return res;
    });
    try {
      //   Execute both requests concurrently
      const [data1, data2] = await Promise.all([apiRequest1, apiRequest2]);

      //   Handle the responses
      // setOtherData(data1); // Update with the data from Endpoint1
      setUsers(data1.data[0].rows); // Update with the data from Endpoint2
      let total = data2.data[0].rows[0].TotalCount; // Update with the data from Endpoint2
      // Update pagination state
      setPagination((prevPagination) => ({
        ...prevPagination,
        current: page,
        pageSize: pageSize,
        total: total, // Update total from your API response
      }));

      // Reset pagination to the first page
      //tableRef.current.onChange(1);
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
    }
  }

  function roleListData() {
    ReadRecord(`SELECT * FROM roles where ActiveStatus = 1`)
      .then((res) => {
        setRoleOptions(res.data[0]?.rows);
        console.log("user roles", res.data[0]?.rows);
      })
      .catch((error) => {
        console.error("Error fetching records:", error);
      })
      .finally(() => setIsLoading(false));
  }
  function roleAccessListData() {
    ReadRecord(`SELECT * FROM roleaccess_master where ActiveStatus = 1`)
      .then((res) => {
        setRoleAccessOptions(res.data[0]?.rows);
        console.log("user access roles", res.data[0]?.rows);
      })
      .catch((error) => {
        console.error("Error fetching records:", error);
      })
      .finally(() => setIsLoading(false));
  }
  const handleSearch = (value) => {
    setSearchQuery(value);
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "serial",
      key: "serial",
      width: 50,
      align: "center",
      render: (text, record, index) =>
        index + (pagination.current - 1) * pagination.pageSize + 1,
    },
    {
      title: "User ID",
      dataIndex: "id",
      key: "id",
      align: "center",
    },

    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      align: "center",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",
    },
    {
      title: "Role",
      dataIndex: "role_name",
      key: "role_name",
      align: "center",
    },

    {
      title: "Phone",
      dataIndex: "mobile_no",
      key: "mobile_no",
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (status) => <span>{status == 1 ? "Active" : "Inactive"}</span>,
    },
    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      align: "center",
      render: (text, record) => (
        <>
          <Button
            icon={<EyeOutlined />}
            type="primary"
            style={{ backgroundColor: "#be991d" }}
            title="View"
            onClick={() => handleView(record)}
          ></Button>
          &nbsp;
          <Button
            icon={<EditOutlined />}
            type="primary"
            style={{ backgroundColor: "#005c9f" }}
            title="Edit"
            onClick={() => handleEdit(record)}
          ></Button>
          &nbsp;
          <Button
            icon={<DeleteOutlined />}
            type="primary"
            style={{ backgroundColor: "#d7524b" }}
            title="Delete"
            onClick={() => handleDelete(record)}
          ></Button>
        </>
      ),
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
    setEditUserData(null);
    setViewUserData(null);
    setUsernameValidationStatus("");
    setUsernameHelpMessage("");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    setEditUserData("");
    setViewUserData("");
    setUsernameValidationStatus("");
    setUsernameHelpMessage("");
    setEmailValidationStatus("");
    setEmailHelpMessage("");
    setMobileHelpMessage("");
    setMobileValidationStatus("");
  };

  // Debounce function to delay API calls while typing
  const debounceCheckUniqueUsername = debounce(async (username) => {
    if (!editUserData) {
      const isUsernameAvailable = await checkUniqueField("username", username);
      console.log("check true or false", isUsernameAvailable);
      setUsernameValidationStatus(isUsernameAvailable ? "error" : "success");
      setUsernameHelpMessage(
        isUsernameAvailable ? "Username already exists" : ""
      );
      return;
    } else {
      const editusernameAvailable = await checkEdit("username", username);
      setUsernameValidationStatus(editusernameAvailable ? "error" : "success");
      setUsernameHelpMessage(
        editusernameAvailable ? "Username already exists" : ""
      );
      return;
    }
  }, 500);

  const checkEdit = async (field, value) => {
    try {
      if (value.trim() == "") {
        return false;
      }
      const res =
        await ReadRecord(`select count(*) as count from user_master where ${field} = '${value}' AND 
            (status=1 or status=0) AND ActiveStatus=1 AND id!='${editUserData.id}'`);
      return res.data[0]?.rows[0].count > 0;
    } catch (error) {
      console.error(`Error checking uniqueness for ${field}:`, error);
      return false;
    }
  };

  const debounceCheckUniqueEmail = debounce(async (email) => {
    if (!editUserData) {
      const isEmailAvailable = await checkUniqueField("email", email);
      setEmailValidationStatus(isEmailAvailable ? "error" : "success");
      setEmailHelpMessage(isEmailAvailable ? "Email already exists" : "");
      return;
    } else {
      const isEmailAvailable = await checkEdit("email", email);
      setEmailValidationStatus(isEmailAvailable ? "error" : "success");
      setEmailHelpMessage(isEmailAvailable ? "Email already exists" : "");
      return;
    }
  }, 500);

  // Add debounce function for phone number validation
  const debounceCheckUniquePhone = debounce(async (phone) => {
    if (!editUserData) {
      const isPhoneAvailable = await checkUniqueField("mobile_no", phone);
      setMobileValidationStatus(isPhoneAvailable ? "error" : "success");
      setMobileHelpMessage(
        isPhoneAvailable ? "Phone number already exists" : ""
      );
      return;
    } else {
      const isPhoneAvailable = await checkEdit("mobile_no", phone);
      setMobileValidationStatus(isPhoneAvailable ? "error" : "success");
      setMobileHelpMessage(
        isPhoneAvailable ? "Phone number already exists" : ""
      );
      return;
    }
  }, 500);

  const onFinish = async (values) => {
    console.log("add clicked", values);
    try {
      if (values.username == undefined || values.username == "") {
        setUsernameValidationStatus("error");
        setUsernameHelpMessage("Please fill username");
        return;
      }
      if (values.email == undefined || values.email == "") {
        setEmailHelpMessage("please fill Email");
        setEmailValidationStatus("error");
        return;
      }
      if (values.mobile_no == undefined || values.mobile_no == "") {
        setMobileHelpMessage("please fill Phone");
        setMobileValidationStatus("error");
        return;
      }

      if (
        usernameValidationStatus == "error" ||
        mobileValidationStatus == "error" ||
        emailValidationStatus == "error"
      ) {
        return;
      }
      submitForm(values);
    } catch (error) {
      console.error("Error fetching existing user count:", error);
    }
  };

  const checkUniqueField = async (field, value) => {
    if (value.trim() == "") {
      return false; // Return false if value is empty
    }
    try {
      const res = await ReadRecord(`
        SELECT COUNT(*) AS count FROM user_master
        WHERE ${field} = '${value}'
        AND (status = 1 or status=0) AND ActiveStatus=1
      `);
      return res.data[0]?.rows[0].count > 0;
    } catch (error) {
      console.error(`Error checking uniqueness for ${field}:`, error);
      return false;
    }
  };

  const submitForm = (values) => {
    let data = {
      role_access_id: values.role_access_id.toString(),
      mobile_no: values.mobile_no,
      role_id: values.role_id,
      username: values.username,
      password: values.password,
      email: values.email,
      status: values.status == "Active" ? 1 : 0,
    };
    if (editUserData) {
      const updatedData = { ...editUserData, ...values };
      updatedData.role_access_id = updatedData.role_access_id.toString();
      updatedData.status = updatedData.status == "Active" ? 1 : 0;
      UpdateRecord("userMaster", [updatedData])
        .then((res) => {
          //userListData();
          apiCheck(pagination.current, pagination.pageSize, searchQuery);

          message.success("Edited successfully");
        })
        .catch((error) => {
          console.error("Error updating record:", error);
        });
    } else {
      CreateRecord("userMaster", [data])
        .then((res) => {
          console.log("inserted data", res);
          setUsers([...users, values]);
          //userListData();
          apiCheck(pagination.current, pagination.pageSize, searchQuery);

          message.success("User added successfully");
        })
        .catch((error) => {
          console.error("Error creating record:", error);
        });
    }

    setIsModalVisible(false);
    form.resetFields();
  };

  const handleView = (record) => {
    const recordWithStatusLabel = { ...record };

    if (typeof record.role_access_id === "string") {
      record.role_access_id = recordWithStatusLabel.role_access_id.split(",");
    }
    recordWithStatusLabel.status =
      recordWithStatusLabel.status == 1 ? "Active" : "Inactive";

    setViewUserData(recordWithStatusLabel);
    setIsModalVisible(true);
    form.setFieldsValue(recordWithStatusLabel);
  };

  const handleEdit = (record) => {
    const recordWithStatusLabel = { ...record };

    if (typeof recordWithStatusLabel.role_access_id === "string") {
      recordWithStatusLabel.role_access_id =
        recordWithStatusLabel.role_access_id.split(",");
    }
    recordWithStatusLabel.status =
      recordWithStatusLabel.status == 1 ? "Active" : "Inactive";
    console.log("record", recordWithStatusLabel);
    setEditUserData(recordWithStatusLabel);
    setIsModalVisible(true);
    form.setFieldsValue(recordWithStatusLabel);
  };

  const handleDelete = (record) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this record?"
    );
    if (isConfirmed) {
      console.log("Before deleting record", record.id);
      let data = {
        id: record.id,
        ActiveStatus: false,
      };
      UpdateRecord("userdeleteMaster", [data]).then((res) => {
        //userListData();
        apiCheck(pagination.current, pagination.pageSize, searchQuery);

        console.log("User record deleted", res);
        message.info("user record has been deleted");
      });
    }
  };

  const handleUsernameChange = (e) => {
    const username = e.target.value;
    if (username.trim() === "" || username.trim() === undefined) {
      setUsernameValidationStatus("error");
      setUsernameHelpMessage("Username cannot be empty.");
      return;
    }
    //Check if the phone number matches the pattern
    const isUserNameValid = /^[a-zA-Z][a-zA-Z0-9. ]+$/.test(username);
    if (!isUserNameValid) {
      setUsernameValidationStatus("error");
      setUsernameHelpMessage("Please enter valid username.");
      return;
    }
    setUsernameValidationStatus("");
    setUsernameHelpMessage("");
    // Call debounce function to check username availability
    debounceCheckUniqueUsername(username);
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    if (email.trim() === "" || email.trim() == undefined) {
      setEmailValidationStatus("error");
      setEmailHelpMessage("Email cannot be empty.");
      return;
    }
    const isUserNameValid =
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
    if (!isUserNameValid) {
      setEmailValidationStatus("error");
      setEmailHelpMessage("Please enter valid Email ID.");
      return;
    }
    setEmailValidationStatus("");
    setEmailHelpMessage("");
    // Call debounce function to check email availability
    debounceCheckUniqueEmail(email);
  };

  // Function to handle phone number change
  const handlePhoneChange = (e) => {
    const phone = e.target.value;
    if (phone.trim() === "" || phone.trim() == undefined) {
      setMobileValidationStatus("error");
      setMobileHelpMessage("Phone number cannot be empty.");
      return;
    }
    const isPhoneValid = /^[6-9][0-9]{9}$/.test(phone);
    if (!isPhoneValid) {
      setMobileValidationStatus("error");
      setMobileHelpMessage("Please enter valid phone number.");
      return;
    }
    setMobileValidationStatus("");
    setMobileHelpMessage("");
    // Call debounce function to check phone number availability
    debounceCheckUniquePhone(phone);
  };
  return (
    <div>
      {isLoading ? (
        <LoadingOutlined>Loading users...</LoadingOutlined>
      ) : (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            <p
              style={{
                color: "#333",
                marginRight: "auto",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              User Master
            </p>
            <Input
              placeholder="Search"
              allowClear
              style={{ width: 350, margin: "0 16px" }}
              prefix={<SearchOutlined />}
              onChange={(e) => handleSearch(e.target.value)}
            />
            <Button type="primary" onClick={showModal} icon={<PlusOutlined />}>
              Add New User
            </Button>
          </div>
          {/* <Table dataSource={filteredUsers} scroll={{y:400}} columns={columns} rowClassName={(record, index) => */}
          <Table
            size="small"
            bordered
            dataSource={users}
            columns={columns}
            rowClassName={(record, index) =>
              index % 2 === 1 ? "even-row" : "odd-row"
            }
            pagination={{
              pageSize: pagination.pageSize,
              onChange: (page, pageSize) => {
                apiCheck(page, pageSize);
              },
              total: pagination.total,
              showSizeChanger: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </>
      )}
      <Modal
        title={
          editUserData
            ? "Edit User"
            : ViewUserData
            ? "View User"
            : "Create User"
        }
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={800}
        maskClosable={false}
      >
        <hr></hr>
        &nbsp;
        <Form
          id="userform"
          className="userform"
          form={form}
          name="createUserForm"
          onFinish={onFinish}
          layout="horizontal" // Changed layout to vertical for better responsiveness
          initialValues={{ status: "Active" }}
          autoComplete="off"
          disabled={ViewUserData}
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 16 }}
        >
          <Row gutter={16}>
            {" "}
            {/* Added Row to create columns */}
            <Col span={12}>
              <Form.Item
                name="username"
                id="username"
                label={
                  <span>
                    <span style={{ color: "#ff4d4f", fontSize: 16 }}>*</span>{" "}
                    Username
                  </span>
                }
                hasFeedback
                validateStatus={usernameValidationStatus}
                help={usernameHelpMessage}
              >
                <Input onChange={handleUsernameChange} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Password"
                name="password"
                id="password"
                rules={[{ required: true, message: "Please enter Password!" }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={
                  <span>
                    <span style={{ color: "#ff4d4f", fontSize: 16 }}>*</span>{" "}
                    Email
                  </span>
                }
                name="email"
                hasFeedback
                validateStatus={emailValidationStatus}
                help={emailHelpMessage}
              >
                <Input onChange={handleEmailChange} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span>
                    <span
                      title="Phonenumber"
                      style={{ color: "#ff4d4f", fontSize: 16 }}
                    >
                      *
                    </span>{" "}
                    Mobile No
                  </span>
                }
                name="mobile_no"
                id="mobile_no"
                hasFeedback
                validateStatus={mobileValidationStatus}
                help={mobileHelpMessage}
              >
                <Input onChange={handlePhoneChange} type="number" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Role"
                name="role_id"
                id="role_id"
                rules={[{ required: true, message: "Please select Role!" }]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {roleOptions &&
                    roleOptions.map((role) => (
                      <Select.Option key={role.id} value={role.role_id}>
                        {role.role_name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Status"
                name="status"
                id="status"
                rules={[{ required: true, message: "Please select status!" }]}
              >
                <Select
                  onChange={(value) => setSelectedStatus(value)}
                  placeholder="Select"
                >
                  <Select.Option value="Active">Active</Select.Option>
                  <Select.Option value="Inactive">Inactive</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Component Access"
                name="role_access_id"
                id="component"
                className="component"
                rules={[
                  {
                    required: true,
                    message: "Please provide component access!",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  showSearch
                  placeholder="Select"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {roleAccessOptions &&
                    roleAccessOptions.map((role) => (
                      <Select.Option
                        key={role.role_access_id}
                        value={role.role_access_id}
                      >
                        {role.role_name}
                      </Select.Option>
                    ))}
                </Select>
                {/* <Select
              mode="multiple"
              options={roleAccessOptions &&
                 roleAccessOptions.map(role => ({ value: role.role_access_id, label: role.role_name }))}
            /> */}
              </Form.Item>
            </Col>
          </Row>

          <Form.Item wrapperCol={{ offset: 12, span: 16 }}>
            {ViewUserData ? null : (
              <>
                <Button
                  type="primary"
                  htmlType="submit"
                  id="customerformsubmit"
                  className="customerformsubmit"
                >
                  {editUserData ? "Update" : "Add"}
                </Button>
                <Button style={{ marginLeft: 8 }} onClick={handleCancel}>
                  Cancel
                </Button>
              </>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default UserMaster;
