import React, { useState, useEffect,useRef } from 'react';
import { Table, Button, Modal, Form, Input, Select, Typography } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined, EyeOutlined, LoadingOutlined,SearchOutlined } from '@ant-design/icons';
import { CreateRecord, ReadRecord, UpdateRecord } from '../../Service/API/ApiService';
import useScanDetection from 'use-scan-detection';
import "./RmInward.css";
import Barcode from 'react-barcode';
import BarcodeReader from 'react-barcode-reader';
import Draggable from 'react-draggable';




import Sidebar from '../../Components/Sidebar/Sidebar';
import Password from 'antd/es/input/Password';
const { Title } = Typography;



const RMInward = () => {

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
 
  const [scannedBarcode, setScannedBarcode] = useState('');


  // useScanDetection({
  //   preventDefault: true,
  //   onComplete: (code) => {
  //     setScannedBarcode(code);
  //     form.setFieldsValue({ barcode_value: code });
  //   },
  //   minLength: 3,
  // });

 
 
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef(null);
  const showModal = () => {
    setOpen(true);
  };
  const handleOk = (e) => {
    console.log(e);
    setOpen(false);
  };
  const handleCancel = (e) => {
    console.log(e);
    setOpen(false);
  };
  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };
  return (
    <>
      <Button onClick={showModal}>Open Draggable Modal</Button>
      <Modal
        title={
          <div
            style={{
              width: '100%',
              cursor: 'move',
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}
            // fix eslintjsx-a11y/mouse-events-have-key-events
            // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
            onFocus={() => {}}
            onBlur={() => {}}
            // end
          >
            Draggable Modal
          </div>
        }
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <p>
          Just don&apos;t learn physics at school and your life will be full of magic and miracles.
        </p>
        <br />
        <p>Day before yesterday I saw a rabbit, and yesterday a deer, and today, you.</p>
      </Modal>
    </>
  );
};

export default RMInward;
