import axios from "axios";
import { BUILD_RESULTS_GENERIC,BUILD_RESULTS_GENERIC_QUARY,PROCESS_TRANSACTION_URL,
    PROCESS_TRANSACTION_UPDATE_URL,
    SECRET_KEY } from "../../Utills/Constants";


export const CreateRecord = (KeyWord, Input) => {
    return axios
        .post(PROCESS_TRANSACTION_URL, {
            data: Input,
            _keyword_: KeyWord,
            secretkey: SECRET_KEY,
        })
        .then(res => {
            console.log("CreateRecord",Input,res);
            
            return res.data
        })
        .catch(error => {
            return { error: error }
        })
}

export const ReadRecord = async(Input) => {
    
    if(Input._keyword_===undefined){
        return axios
        .post(BUILD_RESULTS_GENERIC_QUARY, {
            "sql-query": Input,
          "sql-query-params": [],
          })
        .then(res => {
            console.log("ReadRecord",Input,res.data);
            return res.data
        })
        .catch(error => {
            console.log(error);
            return {data:[{count:'',rows:''}]}
        })
    }else{
        return axios
        .post(BUILD_RESULTS_GENERIC, Input)
        .then(res => {
            console.log("ReadRecord",Input,res.data);
            return res.data
        })
        .catch(error => {
            console.log(error);
            return {data:[{count:'',rows:''}]}
        })
    }
    
}
export const UpdateRecord = (KeyWord, input) => {

    return axios
        .post(PROCESS_TRANSACTION_UPDATE_URL, {
            data: input,
            _keyword_: KeyWord,
            secretkey: SECRET_KEY,
        })
        .then(res => {
            console.log("UpdateRecord",KeyWord,input,res.data);
            return res.data
        })
        .catch(error => {
            return { error: error }
        })
}
export const DeleteRecord = (KeyWord, id) => {
    return axios
        .post(PROCESS_TRANSACTION_UPDATE_URL, {
            data: [{
                Id: id + "",
                ActiveStatus: "0"
            }],
            _keyword_: KeyWord,
            secretkey: SECRET_KEY,
        })
        .then(res => {
            return res.data
        })
        .catch(error => {
            return { error: error }
        })
}

