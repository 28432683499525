import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Select,
  message,
  Row,
  Col,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  ReadRecord,
  CreateRecord,
  UpdateRecord,
} from "../../../Service/API/ApiService";
import Card from "antd/es/card/Card";
import { render } from "@testing-library/react";

const RibbonMaster = () => {
  const [users, setUsers] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editUserData, setEditUserData] = useState(null);
  const [ViewUserData, setViewUserData] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("Active");
  const [searchQuery, setSearchQuery] = useState("");
  const [ribbonValidationStatus, setRibbonValidationStatus] = useState("");
  const [ribbonHelpMessage, setRibbonHelpMessage] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  useEffect(() => {
    apiCheck(pagination.current, pagination.pageSize, searchQuery);
    localStorage.setItem("Title", "Core");
    //userListData();
  }, [searchQuery]);

  async function apiCheck(page, pageSize, search = "") {
    let searchFilter = "";
    let offset = (page - 1) * pageSize;
    if (search != "") {
      if (search.toLowerCase().trim() === "active") {
        searchFilter = `AND status = 1`;
      } else if (search.toLowerCase().trim() === "inactive") {
        searchFilter = `AND status = 0`;
      } else {
        searchFilter = `AND concat(ribbon_width, " x ", ribbon_length, " ",ribbon_measure) like '%${search.trim()}%'`;
      }
      offset = 0;
    }

    let searchData = `SELECT *,concat(ribbon_width, " x ", ribbon_length, " ",ribbon_measure) as ribbon_size,status from ribbon_master where ActiveStatus=1 ${searchFilter}  ORDER BY status DESC, id DESC  limit ${pageSize} offset ${offset}`;
    let searchDataCount = `SELECT COUNT(*) as TotalCount
    FROM ribbon_master 
    WHERE ActiveStatus = 1 ${searchFilter}`;
    const apiRequest1 = ReadRecord(searchData).then((res) => {
      console.log("apiRequest1", res);
      return res;
    });
    const apiRequest2 = ReadRecord(searchDataCount).then((res) => {
      console.log("apiRequest2", res);
      return res;
    });
    try {
      //   Execute both requests concurrently
      const [data1, data2] = await Promise.all([apiRequest1, apiRequest2]);

      //   Handle the responses
      // setOtherData(data1); // Update with the data from Endpoint1
      setUsers(data1.data[0].rows); // Update with the data from Endpoint2
      let total = data2.data[0].rows[0].TotalCount; // Update with the data from Endpoint2
      // Update pagination state
      setPagination((prevPagination) => ({
        ...prevPagination,
        current: page,
        pageSize: pageSize,
        total: total, // Update total from your API response
      }));

      // Reset pagination to the first page
      //tableRef.current.onChange(1);
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
    }
  }

  const columns = [
    {
      title: "S.No.",
      dataIndex: "serial",
      key: "serial",
      align: "center",

      render: (text, record, index) =>
        index + (pagination.current - 1) * pagination.pageSize + 1,
    },
    {
      title: "Ribbon Size",
      dataIndex: "ribbon_length",
      align: "center",
      key: "Ribbon Length",
      render: (text, record) => (
        <span>
          {record?.ribbon_width +
            " x " +
            record?.ribbon_length +
            " " +
            record?.ribbon_measure}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",

      render: (status) => <span>{status == 1 ? "Active" : "Inactive"}</span>,
    },
    {
      title: "Actions",
      dataIndex: "Actions",
      align: "center",

      key: "Actions",
      render: (text, record) => (
        <>
          <Button
            icon={<EyeOutlined />}
            style={{ backgroundColor: "#be991d" }}
            type="primary"
            title="View"
            onClick={() => handleView(record)}
          ></Button>
          &nbsp;
          <Button
            icon={<EditOutlined />}
            style={{ backgroundColor: "#005c9f" }}
            type="primary"
            title="Edit"
            onClick={() => handleEdit(record)}
          ></Button>
          &nbsp;
          <Button
            icon={<DeleteOutlined />}
            type="primary"
            style={{ backgroundColor: "#d7524b" }}
            title="Delete"
            onClick={() => handleDelete(record)}
          ></Button>
        </>
      ),
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
    setEditUserData(null);
    setViewUserData(null);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    setEditUserData("");
    setViewUserData("");
    setRibbonHelpMessage("");
    setRibbonValidationStatus("");
  };

  const onFinish = (values) => {
    console.log("values", values);
    const quantityPattern = /^(?!0)\d+(\.\d+)?$/;

    // Check if the quantity matches the pattern
    if (
      !quantityPattern.test(values.ribbon_length) ||
      !quantityPattern.test(values.ribbon_width)
    ) {
      setRibbonValidationStatus("error");
      setRibbonHelpMessage("Please enter a valid Input");
      return;
    }

    if (
      values.ribbon_length == undefined ||
      values.ribbon_length == "" ||
      values.ribbon_width == undefined ||
      values.ribbon_width == ""
    ) {
      setRibbonValidationStatus("error");
      setRibbonHelpMessage("Please enter ups");
      return;
    }
    if (editUserData) {
      const existingCore = ReadRecord(`
        SELECT COUNT(*) AS count FROM ribbon_master
        WHERE ribbon_length = '${values.ribbon_length}' and ribbon_width = '${values.ribbon_width}'
        AND (status = 1 or status=0) AND ActiveStatus=1 AND id!='${editUserData.id}'
      `).then((res) => {
        console.log("core count", res.data[0]?.rows[0].count);
        if (res.data[0]?.rows[0].count == 0) {
          submitForm(values);
        } else {
          setRibbonValidationStatus("error");
          setRibbonHelpMessage("Ribbon Size and UPS already existing");
          message.error("Already Exists!");
        }
      });
    } else {
      const existingCore = ReadRecord(`
        SELECT COUNT(*) AS count FROM ribbon_master
        WHERE ribbon_length = '${values.ribbon_length}' and ribbon_width = '${values.ribbon_width}'
        AND (status = 1 or status=0) AND ActiveStatus=1
      `).then((res) => {
        console.log("core count", res.data[0]?.rows[0].count);
        if (res.data[0]?.rows[0].count == 0) {
          submitForm(values);
        } else {
          setRibbonValidationStatus("error");
          setRibbonHelpMessage("Ribbon Size and UPS already existing");
          message.error("Already Exists!");
        }
      });
    }
  };
  const submitForm = (values) => {
    let data = {
      // label_size: values.label_size,
      // ups: values.ups,
      status: values.status == "Active" ? 1 : 0,
      ribbon_measure: values.ribbon_measure,
      ribbon_width: values.ribbon_width,
      ribbon_length: values.ribbon_length,
    };
    if (editUserData) {
      const updatedData = { ...editUserData, ...values };
      updatedData.status = updatedData.status == "Active" ? 1 : 0;

      UpdateRecord("ribbonMaster", [updatedData])
        .then((res) => {
          message.success("Ribbon updated successfully");

          apiCheck(pagination.current, pagination.pageSize, searchQuery);
          setIsModalVisible(false);
        })
        .catch((error) => {
          console.error("Error updating record:", error);
        });
      // const updatedUsers = users.map((user) =>
      //   user.userId === editUserData.userId ? { ...user, ...values } : user
      // );
      //setUsers(updatedUsers);
    } else {
      CreateRecord("ribbonMaster", [data]).then((res) => {
        message.success("Ribbon added successfully");
        console.log("created ribbon data", res);
        setUsers([...users, values]);
        apiCheck(pagination.current, pagination.pageSize, searchQuery);
      });
    }
    setIsModalVisible(false);
    form.resetFields();
    handleCancel();
  };

  const handleView = (record) => {
    const recordWithStatusRibbon = { ...record };

    recordWithStatusRibbon.status =
      recordWithStatusRibbon.status == 1 ? "Active" : "Inactive";

    setViewUserData(recordWithStatusRibbon);
    setIsModalVisible(true);
    form.setFieldsValue(recordWithStatusRibbon);
  };

  const handleEdit = (record) => {
    const recordWithStatusRibbon = { ...record };

    recordWithStatusRibbon.status =
      recordWithStatusRibbon.status == 1 ? "Active" : "Inactive";
    console.log("record", recordWithStatusRibbon);
    setEditUserData(recordWithStatusRibbon);
    setIsModalVisible(true);
    form.setFieldsValue(recordWithStatusRibbon);
  };

  const handleDelete = (record) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this record?"
    );
    if (isConfirmed) {
      console.log("Before deleting record", record.id);
      let data = {
        id: record.id,
        ActiveStatus: 0,
      };
      UpdateRecord("ribbonMaster", [data]).then((res) => {
        message.info("Ribbon has been deleted");
        apiCheck(pagination.current, pagination.pageSize, searchQuery);
        console.log("User record deleted", res);
      });
    }
  };

  const handleSearch = (value) => {
    setSearchQuery(value);
  };

  return (
    <div>
      <>
        <div style={{ display: "flex", alignItems: "center" }}>
          <p
            style={{
              color: "#333",
              marginRight: "auto",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            Ribbon Master
          </p>
          <Input
            placeholder="Search"
            allowClear
            style={{ width: 350, margin: "0 16px" }}
            prefix={<SearchOutlined />}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <Button type="primary" onClick={showModal} icon={<PlusOutlined />}>
            Add New Ribbon
          </Button>
        </div>
        {/* <Table dataSource={filteredUsers} scroll={{y:400}} columns={columns} rowClassName={(record, index) => */}
        <Table
          size="small"
          bordered
          dataSource={users}
          columns={columns}
          rowClassName={(record, index) =>
            index % 2 === 1 ? "even-row" : "odd-row"
          }
          pagination={{
            pageSize: pagination.pageSize,
            onChange: (page, pageSize) => {
              apiCheck(page, pageSize);
            },
            total: pagination.total,
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
      </>

      <Modal
        title={
          editUserData
            ? "Edit Ribbon"
            : ViewUserData
            ? "View Ribbon "
            : "Create Ribbon"
        }
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        maskClosable={false}
      >
        <hr></hr>
        &nbsp;
        <Form
          form={form}
          name="createUserForm"
          onFinish={onFinish}
          layout="vertical"
          // labelCol={{ span: 8 }}
          wrapperCol={{ span: 24 }}
          id="ribbonform"
          autoComplete="off"
          className="ribbonform"
          disabled={ViewUserData}
          initialValues={{ status: "Active", ribbon_measure: "mm" }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Ribbon Width"
                name="ribbon_width"
                id="ribbonwidth"
                className="ribbonwidth"
                rules={[
                  { required: true, message: "Please enter width size!" },
                  {
                    pattern: /^(?!0)\d+(\.\d+)?$/,
                    message: "Only number values!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Ribbon Length"
                name="ribbon_length"
                id="ribbonlength"
                className="ribbonlength"
                rules={[
                  { required: true, message: "Please enter ribbon size!" },
                  {
                    pattern: /^(?!0)\d+(\.\d+)?$/,
                    message: "Only number values!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Unit of Measure"
                name="ribbon_measure"
                wrapperCol={{ span: 12 }}
              >
                <Select placeholder="Size">
                  <Select.Option label="mm" id="mm" value="mm">
                    mm
                  </Select.Option>
                  <Select.Option label="cm" id="cm" value="cm">
                    cm
                  </Select.Option>
                  <Select.Option label="M" id="M" value="M">
                    M
                  </Select.Option>
                  <Select.Option label="inches" id="inches" value="inches">
                    inches
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Status"
                name="status"
                id="status"
                className="status"
                rules={[{ required: true, message: "Please select status!" }]}
              >
                <Select
                  onChange={(value) => setSelectedStatus(value)}
                  placeholder="Select"
                >
                  <Select.Option value="Active">Active</Select.Option>
                  <Select.Option value="Inactive">Inactive</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            {ViewUserData ? null : (
              <>
                <Button
                  type="primary"
                  htmlType="submit"
                  id="customerformsubmit"
                  className="customerformsubmit"
                >
                  {editUserData ? "Update" : "Add "}
                </Button>
                <Button style={{ marginLeft: 8 }} onClick={handleCancel}>
                  Cancel
                </Button>
              </>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default RibbonMaster;
