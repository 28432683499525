import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Card,
  Select,
  message,
  Col,
  Row,
  InputNumber,
  Radio,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  CreateRecord,
  ReadRecord,
  UpdateRecord,
} from "../../../Service/API/ApiService";

const JumboMaster = () => {
  const [users, setUsers] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editUserData, setEditUserData] = useState(null);
  const [ViewUserData, setViewUserData] = useState(null);
  const [jumboValidationStatus, setJumboValidationStatus] = useState("");
  const [jumboHelpMessage, setJumboHelpMessage] = useState("");
  const [MaterialOptions, setMaterialDetails] = useState(null);
  const [typeFilter, settypeFilter] = useState("All");

  const [searchQuery, setSearchQuery] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  useEffect(() => {
    apiCheck(pagination.current, pagination.pageSize, searchQuery);
    localStorage.setItem("Title", "Core");

    //userListData();
  }, [searchQuery]);
  useEffect(() => {
    getMaterialDetail();
  }, []);

  function getMaterialDetail() {
    ReadRecord(
      `select id,material_name from material_master where status=1 and ActiveStatus=1`
    ).then((res) => {
      setMaterialDetails(res.data[0]?.rows);
    });
  }
  async function apiCheck(page, pageSize, search = "", typeFilter = "All") {
    let searchFilter = "";
    let offset = (page - 1) * pageSize;
    if (search != "") {
      if (search.toLowerCase().trim() === "active") {
        searchFilter = `AND t1.status = 1`;
      } else if (search.toLowerCase().trim() === "inactive") {
        searchFilter = `AND t1.status = 0`;
      } else {
        searchFilter = `AND (t1.jumbo_type like '%${search}%' OR t2.material_name like '%${search}%')`;
      }
      offset = 0;
    }
    let searchtypeFilter = "";
    if (typeFilter != "All") {
      searchtypeFilter = `AND t1.type='${typeFilter}'`;
    }

    let searchData = `SELECT t1.*,t2.material_name from jumbo_master t1 join 
    material_master t2 ON t1.material_id=t2.id
     where t1.ActiveStatus=1 ${searchFilter} ${searchtypeFilter}  ORDER BY status DESC, id DESC  limit ${pageSize} offset ${offset}`;
    let searchDataCount = `SELECT COUNT(*) as TotalCount
   from jumbo_master t1 join 
    material_master t2 ON t1.material_id=t2.id
     where t1.ActiveStatus=1 ${searchFilter} ${searchtypeFilter}`;
    const apiRequest1 = ReadRecord(searchData).then((res) => {
      console.log("apiRequest1", res);
      return res;
    });
    const apiRequest2 = ReadRecord(searchDataCount).then((res) => {
      console.log("apiRequest2", res);
      return res;
    });
    try {
      //   Execute both requests concurrently
      const [data1, data2] = await Promise.all([apiRequest1, apiRequest2]);

      //   Handle the responses
      // setOtherData(data1); // Update with the data from Endpoint1
      setUsers(data1.data[0].rows); // Update with the data from Endpoint2
      let total = data2.data[0].rows[0].TotalCount; // Update with the data from Endpoint2
      // Update pagination state
      setPagination((prevPagination) => ({
        ...prevPagination,
        current: page,
        pageSize: pageSize,
        total: total, // Update total from your API response
      }));

      // Reset pagination to the first page
      //tableRef.current.onChange(1);
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
    }
  }

  const columns = [
    {
      title: "S.No.",
      dataIndex: "serial",
      key: "serial",
      align: "center",
      render: (text, record, index) =>
        index + (pagination.current - 1) * pagination.pageSize + 1,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      align: "center",
      render: (type) => (
        <span>{type == 1 ? "Label" : type == 2 ? "Ribbon" : ""}</span>
      ),
    },

    {
      title: "Jumbo Type",
      dataIndex: "jumbo_type",
      key: "Jumbo Type",
      align: "center",
    },
    {
      title: "Material",
      dataIndex: "material_name",
      key: "material_name",
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (status) => <span>{status == 1 ? "Active" : "Inactive"}</span>,
    },

    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      align: "center",
      render: (text, record) => (
        <>
          <Button
            icon={<EyeOutlined />}
            style={{ backgroundColor: "#be991d" }}
            type="primary"
            title="View"
            onClick={() => handleView(record)}
          ></Button>
          &nbsp;
          <Button
            icon={<EditOutlined />}
            style={{ backgroundColor: "#005c9f" }}
            type="primary"
            title="Edit"
            onClick={() => handleEdit(record)}
          ></Button>
          &nbsp;
          <Button
            icon={<DeleteOutlined />}
            type="primary"
            style={{ backgroundColor: "#d7524b" }}
            title="Delete"
            onClick={() => handleDelete(record)}
          ></Button>
        </>
      ),
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
    setEditUserData(null);
    setViewUserData(null);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    setEditUserData("");
    setViewUserData("");
    setJumboValidationStatus("");
    setJumboHelpMessage("");
  };
  const onFinish = (values) => {
    console.log("allvalues", editUserData);

    if (values.jumbo_type == undefined || values.jumbo_type == "") {
      setJumboValidationStatus("error");
      setJumboHelpMessage("Please Enter Jumbo");
      return;
    } else {
      if (editUserData) {
        const existingCore = ReadRecord(`
          SELECT COUNT(*) AS count FROM jumbo_master
          WHERE jumbo_type = '${values.jumbo_type}'
          AND (status = 1 or status=0) AND ActiveStatus=1 AND id!='${editUserData.id}'
        `).then((res) => {
          console.log("core count", res.data[0]?.rows[0].count);
          if (res.data[0]?.rows[0].count == 0) {
            submitForm(values);
          } else {
            setJumboValidationStatus("error");
            setJumboHelpMessage("Jumbo type already existing");
            return;
          }
        });
      } else {
        const existingCore = ReadRecord(`
          SELECT COUNT(*) AS count FROM jumbo_master
          WHERE jumbo_type = '${values.jumbo_type}'
          AND (status = 1 or status=0) AND ActiveStatus=1
        `).then((res) => {
          console.log("core count", res.data[0]?.rows[0].count);
          if (res.data[0]?.rows[0].count == 0) {
            submitForm(values);
          } else {
            setJumboValidationStatus("error");
            setJumboHelpMessage("Jumbo type already existing");
            message.error("Already Exists!");
            return;
          }
        });
      }
    }
  };

  const submitForm = (values) => {
    console.log("submitform values", values);
    let data = {
      jumbo_type: values.jumbo_type,
      material_id: values.material_id,
      status: values.status == "Active" ? 1 : 0,
      type: values.type,
    };
    if (editUserData) {
      const updatedData = { ...editUserData, ...values };
      updatedData.status = updatedData.status == "Active" ? 1 : 0;

      UpdateRecord("jumboMaster", [updatedData])
        .then((res) => {
          apiCheck(pagination.current, pagination.pageSize, searchQuery);
          message.success("Jumbo updated successfully");

          setIsModalVisible(false);
        })
        .catch((error) => {
          console.error("Error updating record:", error);
        });
      // const updatedUsers = users.map((user) =>
      //   user.userId === editUserData.userId ? { ...user, ...values } : user
      // );
      // setUsers(updatedUsers);
    } else {
      CreateRecord("jumboMaster", [data]).then((res) => {
        message.success("Jumbo added successfully");

        console.log("created jumbo data", res);
        setUsers([...users, values]);
        apiCheck(pagination.current, pagination.pageSize, searchQuery);
      });
    }
    setIsModalVisible(false);
    form.resetFields();
    handleCancel();
  };

  const handleView = (record) => {
    const recordWithStatusLabel = { ...record };

    recordWithStatusLabel.status =
      recordWithStatusLabel.status == 1 ? "Active" : "Inactive";

    setViewUserData(recordWithStatusLabel);
    setIsModalVisible(true);
    form.setFieldsValue(recordWithStatusLabel);
  };

  const handleEdit = (record) => {
    const recordWithStatusLabel = { ...record };

    recordWithStatusLabel.status =
      recordWithStatusLabel.status == 1 ? "Active" : "Inactive";
    console.log("record", recordWithStatusLabel);
    setEditUserData(recordWithStatusLabel);
    setIsModalVisible(true);
    form.setFieldsValue(recordWithStatusLabel);
  };

  const handleDelete = (record) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this record?"
    );
    if (isConfirmed) {
      console.log("Before deleting record", record.id);
      let data = {
        id: record.id,
        ActiveStatus: false,
      };
      UpdateRecord("deleteJumboMaster", [data]).then((res) => {
        message.info("Jumbo has been deleted");

        apiCheck(pagination.current, pagination.pageSize, searchQuery);
        console.log("User record deleted", res);
      });
    }
  };

  const handleSearch = (value) => {
    setSearchQuery(value);
  };
  const options = [
    {
      label: "All",
      value: "All",
    },
    {
      label: "Label",
      value: "1",
    },
    {
      label: "Ribbon",
      value: "2",
      title: "Ribbon",
    },
  ];
  const handleTypeFilter = (value) => {
    console.log("keyfilter", value.target.value);
    settypeFilter(value.target.value);
    apiCheck(
      1,
      pagination.pageSize,
      searchQuery,
      value.target.value
    );
  };
  return (
    <div>
      <>
        <div style={{ display: "flex", alignItems: "center" }}>
          <p
            style={{
              color: "#333",
              marginRight: "auto",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            Jumbo Master
          </p>
          <Radio.Group
            options={options}
            onChange={(e) => handleTypeFilter(e)}
            value={typeFilter}
            optionType="button"
            buttonStyle="solid"
          />

          <Input
            placeholder="Search"
            allowClear
            style={{ width: 350, margin: "0 16px" }}
            prefix={<SearchOutlined />}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <Button type="primary" onClick={showModal} icon={<PlusOutlined />}>
            Add New Jumbo
          </Button>
        </div>
        {/* <Table dataSource={filteredUsers} scroll={{y:400}} columns={columns} rowClassName={(record, index) => */}
        <Table
          size="small"
          bordered
          dataSource={users}
          columns={columns}
          rowClassName={(record, index) =>
            index % 2 === 1 ? "even-row" : "odd-row"
          }
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            onChange: (page, pageSize) => {
              apiCheck(page, pageSize);
            },
            total: pagination.total,
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
      </>

      <Modal
        title={
          editUserData
            ? "Edit Jumbo"
            : ViewUserData
            ? "View Jumbo "
            : "Create Jumbo"
        }
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        maskClosable={false}
        width={600}
      >
        <hr></hr>
        &nbsp;
        <Form
          form={form}
          name="createUserForm"
          onFinish={onFinish}
          layout="horizontal"
          labelCol={{ span: 8 }}
          id="jumboform"
          className="jumboform"
          wrapperCol={{ span: 10 }}
          disabled={ViewUserData}
          initialValues={{ status: "Active", size: "m" }}
          autoComplete="off"
        >
          <Form.Item
            label={
              <span>
                <span title="Jumbo" style={{ color: "#ff4d4f", fontSize: 16 }}>
                  *
                </span>{" "}
                Jumbo
              </span>
            }
            name="jumbo_type"
            id="jumbotype"
            className="jumbotype"
            validateStatus={jumboValidationStatus} // Validate status
            help={jumboHelpMessage} // Help message
            // rules={[{ required: true, message: "Please enter jumbo!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={"Material"}
            name="material_id"
            id="jumbomaterialtype"
            className="jumbomaterialtype"
            rules={[{ required: true, message: "Please select Material!" }]}
          >
            <Select
              allowClear
              showSearch
              placeholder="Select Material"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {MaterialOptions &&
                MaterialOptions.map((loc) => (
                  <Select.Option key={loc.id} value={loc.id}>
                    {loc.material_name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Type"
            name="type"
            rules={[{ required: true, message: "Please select Type!" }]}
          >
            <Select
              // onChange={handleOptionSelect}
              placeholder="Please select type"
            >
              <Select.Option label="Label" id="1" value="1">
                Label
              </Select.Option>
              <Select.Option label="Ribbon" id="2" value="2">
                Ribbon
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Status"
            name="status"
            id="status"
            className="status"
            rules={[{ required: true, message: "Please select status!" }]}
          >
            <Select placeholder="Select">
              <Select.Option value="Active">Active</Select.Option>
              <Select.Option value="Inactive">Inactive</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            {ViewUserData ? null : (
              <>
                <Button
                  type="primary"
                  htmlType="submit"
                  id="customerformsubmit"
                  className="customerformsubmit"
                >
                  {editUserData ? "Update" : "Add Jumbo"}
                </Button>
                <Button style={{ marginLeft: 8 }} onClick={handleCancel}>
                  Cancel
                </Button>
              </>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default JumboMaster;
